import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Segment, Container } from "semantic-ui-react";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';

import MiniMasthead from "../components/MiniMasthead";
import CreditCardForm from "../components/CreditCardForm";
import config from "../config";

const stripePromise = loadStripe(config.stripe.API_KEY);

export default class UpdateSubscription extends Component {
  render() {
    const childProps = {
      ...this.props,
      update: true
    };
    const { getCanonicalLink } = this.props;

    const helmet = (
      <Helmet>
        <title>Update Credit Card | Green Ocean</title>
        <link rel="canonical" href={getCanonicalLink("/updatesubscription")} />
      </Helmet>
    );

    return (
      <div>
        {helmet}
        <MiniMasthead text="Card" />

        <Container text textAlign="left">
          <Segment basic size="huge">
            <Elements stripe={stripePromise}>
              <ElementsConsumer>
                {({stripe, elements}) => (
                  <CreditCardForm stripe={stripe} elements={elements} {...childProps} />
                )}
              </ElementsConsumer>
            </Elements>
          </Segment>
        </Container>
      </div>
    );
  }
}
