import React, { Component } from 'react';

import { Container, Segment, Grid, Header } from 'semantic-ui-react';

import Text from './Text';

import ImageMasthead from '../components/ImageMasthead';

import LoaderButton from './LoaderButton';

class UpNext extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };

    this.handleRecommendedClick = this.handleRecommendedClick.bind(this);
  }

  handleRecommendedClick(e) {
    e && e.preventDefault();

    const { history, showId, episodeId, chapterId } = this.props;

    this.setState({ isLoading: true });

    const route = `/watch/show/${showId}/episode/${episodeId}/chapter/${chapterId}`;

    setTimeout(() => {
      history.push(route);
    }, 200);
  }

  render() {
    const {
      showTitle,
      episodeTitle,
      episodeDescription,
      episodeThumbails,
      episodeIndex,
      status
    } = this.props;

    let imgSrc;

    try {
      imgSrc = episodeThumbails.original;
    } catch (e) {
      console.log('no original thumb');
    }

    // The label of the button should be based off the status returned in the response
    // Continue will indicate that the user has not finished the last watched chapter
    // NextChapter will indicate that the response is for the next chapter in the current episode
    // NextEpisode will indicate that the response is for the first chapter of the next episode

    let buttonLabel;

    switch (status) {
      case 'Continue':
        buttonLabel = 'Continue';
        break;

      case 'NextChapter':
        buttonLabel = 'Watch Now';
        break;

      case 'NextEpisode':
        buttonLabel = 'Watch Now';
        break;

      default:
        buttonLabel = 'Watch Now';
        break;
    }

    return (
      <div>
        <ImageMasthead
          handleRecommendedClick={this.handleRecommendedClick}
          src={imgSrc}
        />

        <Segment basic>
          <Container>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width="12">
                  <Header as="h2" onClick={this.handleRecommendedClick}>
                    {showTitle}
                  </Header>

                  <Text size="large">
                    <strong>
                      Episode {episodeIndex}: {episodeTitle} -
                    </strong>{' '}
                    {episodeDescription}
                  </Text>
                </Grid.Column>
                <Grid.Column width="4" textAlign="right">
                  <LoaderButton
                    primary
                    fluid
                    size="huge"
                    icon
                    iconName="play"
                    text={buttonLabel}
                    loadingText="Loading…"
                    onClick={this.handleRecommendedClick}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </div>
    );
  }
}
export default UpNext;
