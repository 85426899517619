import React, { Component } from 'react';

import {
  Segment,
  Container,
  Header,
  Message,
  Grid,
  Form,
  Responsive
} from 'semantic-ui-react';

import { Helmet } from 'react-helmet';

import MiniMasthead from '../components/MiniMasthead';

import SecondaryActionLink from '../components/SecondaryActionLink';

import LoaderButton from '../components/LoaderButton';

import { getCurrentUser, publishSubscriptionAction } from '../libs/awsLib';

import { deleteSubscription } from '../libs/apiLib';

export default class CancelSubscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscriptionsTopicArn: null,
      isLoading: false
    };
  }

  handleCancelSubscription = async e => {
    e.preventDefault();

    this.setState({ isLoading: true });

    const { email, history } = this.props;

    const cognitoUser = getCurrentUser();

    try {
      await deleteSubscription(cognitoUser.username);

      await publishSubscriptionAction(
        cognitoUser.username,
        email,
        'DeleteRequested'
      );

      // delayed to account for db update
      // before fetching subscription details on account page
      setTimeout(() => {
        history.push('/account');
      }, 1000);
    } catch (e) {
      console.log('get subscription status failed.');
      console.log(e);
    }
  };

  handleCancel = e => {
    e.preventDefault();
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.props.history.push('/account');
    }, 200);
  };

  render() {
    const { isLoading } = this.state;

    const helmet = (
      <Helmet>
        <title>Cancel Subscription | Green Ocean</title>
      </Helmet>
    );

    return (
      <div>
        {helmet}
        <MiniMasthead text="Cancel Subscription" />

        <Segment vertical>
          <Container text textAlign="left" style={{ marginTop: '2em' }}>
            <Header as="h3">Are you sure you want to cancel?</Header>
          </Container>

          <Container text style={{ marginTop: '2em' }}>
            <Message
              color="red"
              icon="warning sign"
              header="Warning!"
              content="Click 'Leave' to cancel your subscription. You will be able to access the premium content until the date that your subscription would normally renew. If you change your mind, you can re-activate your subscription at any time."
            />
          </Container>

          <Container text style={{ marginTop: '2em' }}>
            <Form onSubmit={this.handleContinue} style={{ marginTop: '2em' }}>
              <Grid stackable>
                <Grid.Row>
                  <Responsive
                    as={Grid.Column}
                    verticalAlign="middle"
                    textAlign="right"
                    width={12}
                    minWidth={768}
                  >
                    <SecondaryActionLink>
                      <a href="" onClick={this.handleCancel}>
                        Stay
                      </a>
                    </SecondaryActionLink>
                  </Responsive>
                  <Grid.Column textAlign="right" width={4}>
                    <LoaderButton
                      primary
                      fluid
                      type="submit"
                      size="big"
                      isLoading={isLoading}
                      text="Leave"
                      onClick={this.handleCancelSubscription}
                      disabled={isLoading}
                    />
                  </Grid.Column>
                  <Responsive
                    as={Grid.Column}
                    verticalAlign="middle"
                    textAlign="right"
                    maxWidth={767}
                  >
                    <SecondaryActionLink>
                      <a href="" onClick={this.handleCancel}>
                        Stay
                      </a>
                    </SecondaryActionLink>
                  </Responsive>
                </Grid.Row>
              </Grid>
            </Form>
          </Container>
        </Segment>
      </div>
    );
  }
}
