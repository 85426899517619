import React, { Component } from "react";
import { Segment, Container, Header, Grid } from "semantic-ui-react";
import Text from "./Text";
import { friendlyRunningTime } from "../libs/dateTimeLib";

export default class ChapterPartDetail extends Component {
  render() {
    const {
      showName,
      description,
      episodeIndex,
      episodeName,
      //chapterId,
      //handlePartClicked,
      episodeDurationSeconds,
      //chapters,
    } = this.props;

    // let partButtonsMarkup = null;

    // if (chapters && chapters.length > 1) {
    //   partButtonsMarkup = (
    //     <div>
    //       <Responsive as={Button.Group} minWidth={992} size="large">
    //         <Button
    //           {...(chapters[0].id === chapterId ? { active: true } : {})}
    //           onClick={() => handlePartClicked(chapters[0].id)}
    //         >
    //           Part 1
    //         </Button>
    //         <Button.Or text="&amp;" />
    //         <Button
    //           {...(chapters[1].id === chapterId ? { active: true } : {})}
    //           onClick={() => handlePartClicked(chapters[1].id)}
    //         >
    //           Part 2
    //         </Button>
    //       </Responsive>
    //       <Responsive as={Button.Group} maxWidth={991} size="small">
    //         <Button
    //           {...(chapters[0].id === chapterId ? { active: true } : {})}
    //           onClick={() => handlePartClicked(chapters[0].id)}
    //         >
    //           Part 1
    //         </Button>
    //         <Button.Or text="&amp;" />
    //         <Button
    //           {...(chapters[1].id === chapterId ? { active: true } : {})}
    //           onClick={() => handlePartClicked(chapters[1].id)}
    //         >
    //           Part 2
    //         </Button>
    //       </Responsive>
    //     </div>
    //   );
    // }

    return (
      <div>
        <Segment basic>
          <Container>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width="16">
                  <Header as="h5">{showName}</Header>
                  <Header as="h2" style={{ marginTop: "0em" }}>
                    E{episodeIndex}: {episodeName}
                  </Header>
                  <Text size="large">
                    Running Time: {friendlyRunningTime(episodeDurationSeconds)}
                  </Text>
                  <Text size="large">{description}</Text>
                </Grid.Column>
                {/* <Grid.Column width="4" textAlign="right">
                  {partButtonsMarkup}
                </Grid.Column> */}
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </div>
    );
  }
}
