// https://docs.heapanalytics.com/docs/event-definition-best-practices
// Before performing analysis in Heap, determining and maintaining a consistent naming
// convention is crucial to enable all members of your team to easily interpret events.
// In general, best practices around naming events are:

// 1) Organize your actions under parts of your product
// 2) Use present tense for your event names
// 3) Specify differences between similar events by adding notes if an event changes,
// if there is a caveat, etc. using our event annotation feature

// Following these principles, we suggest using this standard pattern for defining events in Heap:

// Category - Event

export const CLICK_CHAPTER_PART = 'Video - Click Chapter Part';
export const CLICK_PLAY_CHAPTER = 'Video - Click Play Chapter';
export const CLICK_PAUSE_CHAPTER = 'Video - Click Pause Chapter';
export const CLICK_RESUME_CHAPTER = 'Video - Click Resume Chapter';
export const CLICK_SEEK_CHAPTER = 'Video - Click Seek Chapter';
export const ADVANCE_TO_NEXT_CHAPTER = 'Video - Advance To Next Chapter';
export const VIDEO_ENDED = 'Video - Chapter Ended';

export const EXCEPTION_FETCH_PERSONA = 'Exception - Fetch Persona';
export const EXCEPTION_SIGNUP = 'Exception - Sign Up';
export const EXCEPTION_CREATE_PERSONA = 'Exception - Create Persona';
export const EXCEPTION_PUBLISH_SIGNUP = 'Exception - Publish New Sign Up';
export const EXCEPTION_SIGNUP_AUTHENTICATE = 'Exception - Sign Up Authenticate';
export const EXCEPTION_SIGNUP_STATUS = 'Exception - Set Sign Up Status';
export const EXCEPTION_SUBSCRIBE = 'Exception - Subscribe';
export const EXCEPTION_UPDATE_SUBSCRIPTION =
  'Exception - Update Payment Details';
export const EXCEPTION_ADD_SUBSCRIPTION =
  'Exception - Add Subscription To Account';
export const EXCEPTION_CONTACT = 'Exception - Contact Form Submit';

export const CHECK_CHAPTER_TASK = 'Task - Check';
export const UNCHECK_CHAPTER_TASK = 'Task - Uncheck';
