import React, { Component } from 'react';

import { Segment, Container, Header, Divider, Label } from 'semantic-ui-react';

import ChapterTaskItem from './ChapterTaskItem';

import './ChapterTasks.css';

import Text from './Text';

import { getLabelFromTaskStatus } from '../libs/tasksLib';

export default class ChapterTasks extends Component {
  render() {
    const {
      username,
      showId,
      episodeId,
      chapterId,
      chapterActions,
      handleActionsClicked
    } = this.props;

    if (
      !chapterActions ||
      !chapterActions.tasks ||
      !chapterActions.tasks.overview
    ) {
      return null;
    }

    const taskComponents =
      chapterActions.tasks.descriptions &&
      chapterActions.tasks.descriptions.map((item, i) => {
        const itemProps = {
          ...item,
          itemIndex: i + 1,
          checked: item.status,
          username,
          showId,
          episodeId,
          chapterId,
          handleActionsClicked
        };

        return <ChapterTaskItem index={i + 1} key={i} {...itemProps} />;
      }, this);

    const actionsLabel = getLabelFromTaskStatus(chapterActions.status) || {
      color: 'blue',
      text: 'Not Yet Started'
    };

    return (
      <div>
        <Segment basic style={{ marginTop: '1em', marginBottom: '2em' }}>
          <Container className="ChapterTasks">
            <Segment inverted>
              <Label color={actionsLabel.color} ribbon="right" size="large">
                {actionsLabel.text}
              </Label>
              <div className="ChapterTasksDetail">
                <Header as="h3" style={{ marginTop: '-0.8em' }}>
                  G.O. Actions&trade;
                </Header>
                <Text size="large">{chapterActions.tasks.overview}</Text>
                <Divider />
                {taskComponents}
              </div>
            </Segment>
          </Container>
        </Segment>
      </div>
    );
  }
}
