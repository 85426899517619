import React, { Component } from "react";
import { Segment, Container, Header, Grid, List } from "semantic-ui-react";

import Text from "./Text";
import LoaderButton from "../components/LoaderButton";

export default class Pricing extends Component {
  render() {
    const { buttonText, handleSubscribeClicked, handleContactUsClicked } = this.props;

    return (
      <div>
        <Segment basic style={{ marginTop: "2em" }}>
          <Container>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width="8">
                  <Header as="h5">Brokers, Teams &amp; Agents</Header>
                  <Header as="h2">$99 / month</Header>
                  <Text size="small">All prices USD.</Text>
                  <Text size="large">The essentials for real estate professionals.</Text>
                  <List bulleted>
                    <List.Item>7-Day Free Trial</List.Item>
                    <List.Item>GO Actions&#8482;</List.Item>
                    <List.Item>An Always Growing Library</List.Item>
                    <List.Item>Cancel Anytime</List.Item>
                  </List>
                  <LoaderButton
                    primary
                    size="big"
                    onClick={handleSubscribeClicked}
                    text={buttonText ? buttonText : "Start For Free"}
                    style={{ marginTop: "1em" }}
                  />
                </Grid.Column>
                <Grid.Column width="1" />
                <Grid.Column width="7">
                  <Header as="h5">Enterprise</Header>
                  <Header as="h2">$199 / month</Header>
                  <Text size="small">All prices USD.</Text>
                  <Text size="large">Site-wide licenses designed for offices and brokerages.</Text>
                  <List bulleted>
                    <List.Item>Exclusive Content</List.Item>
                    <List.Item>Custom Uploaded Shows</List.Item>
                    <List.Item>Downloadable Materials</List.Item>
                    <List.Item>Agent Analytics</List.Item>
                  </List>
                  <LoaderButton
                    basic
                    size="big"
                    text="Contact Us"
                    onClick={handleContactUsClicked}
                    style={{ marginTop: "1em" }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>

        <Segment
          basic
          style={{
            marginTop: "2em",
            paddingTop: "4em",
            paddingBottom: "4em",
            background: "#f1f1f1",
            marginBottom: "-16px",
          }}
        >
          <Container>
            <Header as="h4">How does the 7-Day Free Trial work?</Header>
            <Text size="medium">
              Pay nothing now. Watch everything as often as you like, no restrictions. Cancel
              whenever you want.
            </Text>
            <Header as="h4">What if I change my mind after I subscribe?</Header>
            <Text size="medium">
              You can cancel your subscription at any time from the Accounts screen and you will not
              be billed again.
            </Text>
            <Header as="h4">How many shows are there?</Header>
            <Text size="medium">
              We're adding shows and episodes all the time. Your subscription includes access to all
              shows and new episodes of those shows.
            </Text>
          </Container>
        </Segment>
      </div>
    );
  }
}
