export default {
  apiGateway: {
    REGION: "us-west-2",
  },
  surveyEndpoint: {
    URL: "https://bb05rt2jvc.execute-api.us-west-2.amazonaws.com/Prod/survey",
    MAX_STEPS: 3,
  },
  personaEndpoint: {
    URL: "https://bb05rt2jvc.execute-api.us-west-2.amazonaws.com/Prod/persona",
  },
  subscriptionsEndpoint: {
    URL: "https://cmwglnml4i.execute-api.us-west-2.amazonaws.com/Prod/subscriptions",
  },
  contentEndpoint: {
    URL: "https://522bnam1lj.execute-api.us-west-2.amazonaws.com/Prod/content",
    MAX_RECOMMENDED: 2,
  },
  userEndpoint: {
    URL: "https://ifi07kbvog.execute-api.us-west-2.amazonaws.com/Prod/user",
  },
  salesTaxEndpoint: {
    URL: "https://l5i83pxtw2.execute-api.us-west-2.amazonaws.com/Prod/salestax/lookup",
  },
  contactEndpoint: {
    URL: "https://x0tjisg7m5.execute-api.us-west-2.amazonaws.com/Prod",
  },
  tasksEndpoint: {
    URL: "https://elqz0yf2v8.execute-api.us-west-2.amazonaws.com/Prod",
  },
  couponEndpoint: {
    URL: "https://xuwy8457jg.execute-api.us-west-2.amazonaws.com/Prod",
  },
  marketingEndpoint: {
    URL: "https://x8323zlgf5.execute-api.us-west-2.amazonaws.com/Prod/marketing",
  },
  cognito: {
    REGION: "us-west-2",
    IDENTITY_POOL_ID: "us-west-2:666c775e-36ee-4dff-b5fa-e0e79e82c773",
    USER_POOL_ID: "us-west-2_kpGZ6U5KE",
    APP_CLIENT_ID: "3i113btrcdovl3l4q29puh960v",
  },
  stripe: {
    API_KEY: "pk_live_GIYc91mZFPKNpW3F5d7m0tYd",
    ENABLE_TRIAL: true,
    TRIAL_DAYS: 7,
  },
  facebook: {
    API_KEY: "1402504693430119",
  },
  sns: {
    ACCOUNTS_TOPIC_ARN: "arn:aws:sns:us-west-2:536923608793:account-events",
    SUBSCRIPTIONS_TOPIC_ARN: "arn:aws:sns:us-west-2:536923608793:subscription-events",
    CONTENT_TOPIC_ARN: "arn:aws:sns:us-west-2:536923608793:content-events",
  },
  defaultPersona: {
    ID: 1,
  },
  home: {
    URL: "https://www.greenoceantv.com",
  },
  app: {
    URL: "https://go.greenoceantv.com",
  },
  player: {
    autoPlay: true,
    autoAdvance: false,
  },
};
