import React, { Component } from "react";
import { Segment, Container, Item } from "semantic-ui-react";

import { Helmet } from "react-helmet";
import MiniMasthead from "../components/MiniMasthead";
import LoadingSpinner from "../components/LoadingSpinner";
import ShowItem from "../components/ShowItem";
import { getAllShows, getUserActions } from "../libs/apiLib";
import { getCurrentUser } from "../libs/awsLib";

export default class LineUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      shows: null,
      userActions: null,
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    const cognitoUser = getCurrentUser();

    if (cognitoUser) {
      try {
        const userActions = await getUserActions(cognitoUser.username);
        this.setState({ userActions: userActions });
      } catch (e) {
        console.log(e);
      }
    }

    try {
      const shows = await getAllShows();
      this.setState({ shows: shows, isLoading: false });
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { history, isAuthenticated, thumbnailSize, getCanonicalLink } = this.props;

    const { shows, isLoading, userActions } = this.state;

    const mastHead = <MiniMasthead text="Now Showing" />;

    const helmet = (
      <Helmet>
        <title>Browse Shows and Episodes | Green Ocean</title>
        <link rel="canonical" href={getCanonicalLink("/shows")} />
        <meta
          name="description"
          content="Modern real estate coaching shows and episodes on topics such as marketing, technology, sales scripts, lead generation and even psychology to give you a competitive edge. New shows added all the time."
        />
      </Helmet>
    );

    if (isLoading) {
      return (
        <div>
          {helmet}
          {mastHead}
          <LoadingSpinner text="Getting shows..." />
        </div>
      );
    }

    const showsMarkup =
      shows &&
      shows.map((item, i) => {
        const showActions =
          userActions &&
          userActions.showRollups &&
          userActions.showRollups.find((actions) => {
            return actions.showId === item.showId;
          });

        const itemProps = {
          ...item,
          history,
          isAuthenticated,
          thumbnailSize,
          showActions,
        };
        return <ShowItem key={item.showId} {...itemProps} />;
      }, this);

    return (
      <div>
        {helmet}
        {mastHead}
        <Segment basic>
          <Container>
            <Item.Group>{showsMarkup}</Item.Group>
          </Container>
        </Segment>
      </div>
    );
  }
}
