import React, { Component } from "react";
import { Message, Container, Segment, Grid } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import MiniMasthead from "../components/MiniMasthead";
import LoadingSpinner from "../components/LoadingSpinner";
import RouteNavButton from "../components/RouteNavButton";
import { getCurrentUser } from "../libs/awsLib";
import { getSubscriptionDetail, getCoupon, updateCoupon } from "../libs/apiLib";

export default class Coupon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      coupon: null,
      existingCoupon: null,
      subscriptionStatusCode: null,
      couponAttempted: false,
      couponApplied: false,
    };
  }

  async componentDidMount() {
    const { getCouponCodeFromLocalStorage } = this.props;

    const cognitoUser = getCurrentUser();
    const couponCode = getCouponCodeFromLocalStorage();

    if (!couponCode) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    const status = {};

    try {
      const coupon = await getCoupon(couponCode);

      if (coupon) {
        status.coupon = {
          code: coupon.code,
          initialPayment: coupon.initialPayment,
          subsequentPayment: coupon.subsequentPayment,
        };
      }
    } catch (e) {
      console.log("no coupon with that Id found.");
    }

    try {
      const subscription = await getSubscriptionDetail(cognitoUser.username);

      if (subscription) {
        status.subscriptionStatusCode = subscription.statusCode;

        if (subscription.coupon) {
          status.existingCoupon = subscription.coupon;
        }
      }
    } catch (e) {
      console.log(e);
    }

    if (status.coupon && status.subscriptionStatusCode && !status.existingCoupon) {
      try {
        const payload = {
          couponCode: couponCode,
          userId: cognitoUser.username,
        };

        status.couponAttempted = true;
        await updateCoupon(cognitoUser.username, payload);
        status.couponApplied = true;
      } catch (e) {
        console.log(e);
      }
    }

    this.setState({
      ...status,
      isLoading: false,
    });
  }

  render() {
    const { getCanonicalLink } = this.props;
    const {
      isLoading,
      coupon,
      existingCoupon,
      subscriptionStatusCode,
      couponAttempted,
      couponApplied,
    } = this.state;

    const helmet = (
      <Helmet>
        <title>Apply Coupon Code | Green Ocean</title>
        <link rel="canonical" href={getCanonicalLink("/apply-coupon-code")} />
      </Helmet>
    );

    if (isLoading) {
      return (
        <div>
          {helmet}
          <MiniMasthead text="Coupon Code" />
          <LoadingSpinner text="Applying coupon code..." />
        </div>
      );
    }

    const couponCodeNotFound = !coupon ? (
      <Message
        warning
        icon="warning sign"
        header="Coupon Failed"
        content="Sorry, we could not find a useable coupon code."
      />
    ) : null;

    const notSubscribed =
      coupon && !subscriptionStatusCode ? (
        <Message
          icon="dollar sign"
          header="Success"
          content="Your GO discount code will be applied when you complete your subscription setup."
          positive
        />
      ) : null;

    const existingCouponApplied =
      coupon && subscriptionStatusCode && existingCoupon ? (
        <Message
          icon="warning sign"
          header="Exising Coupon"
          content="Your subscription already has a GO discount code applied. Sorry, you can't have two!"
          warning
        />
      ) : null;

    const couponCodeApplied =
      coupon && subscriptionStatusCode && couponApplied ? (
        <Message
          icon="dollar sign"
          header="Coupon Applied"
          content="Your GO discount code has been applied. Enjoy!"
          positive
        />
      ) : null;

    const couponCodeFailed =
      couponAttempted && !couponApplied ? (
        <Message
          icon="warning sign"
          header="Coupon Failed"
          content="Sorry, we could not apply that coupon code to your subscription."
          warning
        />
      ) : null;

    const continueHref = notSubscribed ? "/subscribe" : "/";

    return (
      <div>
        {helmet}
        <MiniMasthead text="Coupon Code" />

        <Container text>
          <Segment basic size="huge" style={{ marginTop: "2em" }}>
            {couponCodeNotFound}
            {notSubscribed}
            {existingCouponApplied}
            {couponCodeApplied}
            {couponCodeFailed}
          </Segment>

          <Segment basic size="huge">
            <Grid stackable>
              <Grid.Row style={{ marginTop: "2em" }}>
                <Grid.Column mobile={16} tablet={8} computer={4} floated="right">
                  <RouteNavButton primary size="big" href={continueHref} fluid>
                    Continue
                  </RouteNavButton>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </div>
    );
  }
}
