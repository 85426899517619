import React, { Component } from "react";
import {
  Segment,
  Container,
  Header,
  Grid,
  Input,
  Message,
  Form,
  Responsive,
} from "semantic-ui-react";

import { Helmet } from "react-helmet";
import LoaderButton from "../components/LoaderButton";
import MiniMasthead from "../components/MiniMasthead";
import SecondaryActionLink from "../components/SecondaryActionLink";
import LoadingSpinner from "../components/LoadingSpinner";
import { validateEmail } from "../libs/validateEmail";
import { isStrongEnough } from "../libs/passwords";
import {
  getUserPool,
  resendVerificationCode,
  authUser,
  getCurrentUser,
  getUserToken,
} from "../libs/awsLib";
import { getSubscriptionStatus } from "../libs/apiLib";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      token: "",
      email: "",
      emailInput: "",
      password: "",
      passwordConfirm: "",
      isEmailValid: true,
      isPasswordMatch: true,
      isPasswordStrong: true,
      setPasswordTokenRequestSent: false,
      setPasswordTokenRequestFailed: false,
      resendVerificationCode: false,
      resendVerificationCodeSent: false,
      resendVerificationCodeFailed: false,
      expiredCodeException: false,
      mismatchCodeException: false,
    };

    this.masthead = <MiniMasthead text="Password" />;

    this.helmet = (
      <Helmet>
        <title>Change Password | Green Ocean</title>
      </Helmet>
    );

    this.header = <Header as="h3">Set a new password</Header>;

    this.focusEmailInput = this.focusEmailInput.bind(this);

    // user could have got here from email link only,
    // unathenticated user will not have email in app state,
    // but might have email in local storage

    // check props first, then query string, then local storage

    this.state.email = this.props.email;

    if (!this.state.email) {
      // email in application state takes precedence over
      // email in query string
      const emailUrlEncoded = this.props.match.params.email;

      if (emailUrlEncoded) {
        this.state.email = decodeURIComponent(emailUrlEncoded);
      }
    }

    if (!this.state.email) {
      // email in query string takes precedence over
      // email in local storage
      this.state.email = this.props.getEmailFromLocalStorage();
    }

    if (this.state.email) {
      const lowercaseEmail = this.state.email.toLowerCase();
      this.state.email = lowercaseEmail;

      // lift up state to application, this also puts email into local storage
      this.props.setEmail(lowercaseEmail);
    }
  }

  focusEmailInput() {
    // Explicitly focus the email text input using the raw DOM API
    this.emailInput && this.emailInput.focus();
  }

  handleChange = (e, data) => {
    // we set state to valid to make any invalid
    // messages go away and to enable the submit button
    // even if the value is not actually valid. We
    // do the actual validation test on submit.
    this.setState({
      [e.target.id]: data.value,
      isEmailValid: true,
      isPasswordMatch: true,
      isPasswordStrong: true,
      isLoading: false,
    });
  };

  handleCancel = (e) => {
    e.preventDefault();
    this.props.history.push("/account");
  };

  handleSubmitRequestSetPasswordToken = async (e) => {
    e.preventDefault();

    this.setState({ isLoading: true });

    const { emailInput } = this.state;
    const lowercaseEmailInput = emailInput.toLowerCase();
    const validData = validateEmail(lowercaseEmailInput);

    if (!validData) {
      this.setState({ isEmailValid: false, isLoading: false });
      return;
    }

    this.setState({ email: lowercaseEmailInput }, () => {
      this.handleRequestSetPasswordToken();
    });
  };

  handleRequestSetPasswordToken = async () => {
    const { email } = this.state;

    try {
      await this.requestSetPasswordToken(email);
      this.setState({ isLoading: false, setPasswordTokenRequestSent: true });
    } catch (e) {
      if (!e || !e.code) {
        this.setState({ isLoading: false });
        return;
      }

      if (e.code === "InvalidParameterException") {
        this.setState({
          resendVerificationCode: true,
          setPasswordTokenRequestFailed: true,
          isLoading: false,
        });
        return;
      }

      this.setState({ setPasswordTokenRequestFailed: true, isLoading: false });
    }
  };

  requestSetPasswordToken(email) {
    const userPool = getUserPool();
    const user = new CognitoUser({ Username: email, Pool: userPool });

    return new Promise((resolve, reject) =>
      user.forgotPassword({
        onSuccess: (result) => resolve(),
        onFailure: (err) => reject(err),
      })
    );
  }

  handleRequestEmailVerificationToken = async () => {
    try {
      await authUser();
      const cognitoUser = getCurrentUser();
      await getUserToken(cognitoUser);
      await resendVerificationCode(cognitoUser);

      this.setState({
        isLoading: false,
        resendVerificationCode: false,
        resendVerificationCodeSent: true,
      });
    } catch (e) {
      console.log(e);
      this.setState({ resendVerificationCodeFailed: true, isLoading: false });
    }
  };

  handleSetPassword = async (e) => {
    e.preventDefault();

    this.setState({ isLoading: true });

    const { token, email, password, passwordConfirm } = this.state;
    const lowercaseEmail = email.toLowerCase();
    const isPasswordMatch = password === passwordConfirm;

    if (!isPasswordMatch) {
      this.setState({ isPasswordMatch: false, isLoading: false });
      return;
    }

    if (!isStrongEnough(password)) {
      this.setState({ isPasswordStrong: false, isLoading: false });
      return;
    }

    try {
      await this.confirmPassword(lowercaseEmail, token, password);

      const cognitoUser = await this.login(lowercaseEmail, password);
      const subscription = await this.getSubscription(cognitoUser.username);

      this.setState({ isLoading: false }, () => {
        this.props.setAuthenticatedStatus(true);
        this.props.setEmail(lowercaseEmail);
        this.props.setSubscribedStatus(subscription, () => {});
        this.props.history.push("/account");
      });
    } catch (e) {
      if (!e || !e.code) {
        this.setState({ isLoading: false });
        return;
      }

      switch (e.code) {
        case "CodeMismatchException":
          this.setState({ mismatchCodeException: true, isLoading: false });
          break;
        case "ExpiredCodeException":
          this.setState({ expiredCodeException: true, isLoading: false });
          break;
        default:
          this.setState({ isLoading: false });
          break;
      }
    }
  };

  async getSubscription(email) {
    try {
      const subscription = await getSubscriptionStatus(email);
      return subscription;
    } catch (e) {
      return null;
    }
  }

  confirmPassword(email, token, password) {
    const userPool = getUserPool();
    const user = new CognitoUser({ Username: email, Pool: userPool });

    return new Promise((resolve, reject) =>
      user.confirmPassword(token, password, {
        onSuccess: (result) => resolve(),
        onFailure: (err) => reject(err),
      })
    );
  }

  login(email, password) {
    const userPool = getUserPool();
    const user = new CognitoUser({ Username: email, Pool: userPool });
    const authenticationData = { Username: email, Password: password };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    return new Promise((resolve, reject) =>
      user.authenticateUser(authenticationDetails, {
        onSuccess: (result) => resolve(user),
        onFailure: (err) => reject(err),
      })
    );
  }

  handleSetNewPassword = (e) => {
    e.preventDefault();

    this.setState({
      expiredCodeException: false,
      isLoading: false,
      token: null,
    });
  };

  componentDidMount() {
    // capture token in the incoming URL
    const token = this.props.match.params.token;

    if (token) {
      this.setState({ token });
    }

    this.focusEmailInput();
  }

  render() {
    const {
      setPasswordTokenRequestFailed,
      setPasswordTokenRequestSent,
      mismatchCodeException,
      expiredCodeException,
      resendVerificationCode,
      resendVerificationCodeSent,
      resendVerificationCodeFailed,
      token,
      email,
      isLoading,
    } = this.state;

    if (isLoading) {
      return (
        <div>
          {this.masthead}
          <LoadingSpinner text="Loading..." />
        </div>
      );
    }

    if (resendVerificationCodeFailed) {
      return this.renderResendVerificationCodeFailed();
    }

    if (resendVerificationCode) {
      return this.renderVerificationCodeRequest();
    }

    if (resendVerificationCodeSent) {
      return this.renderVerificationCodeRequestSent();
    }

    if (setPasswordTokenRequestFailed) {
      return this.renderSetPasswordRequestFailed();
    }

    if (setPasswordTokenRequestSent) {
      return this.renderSetPasswordTokenRequestSent();
    }

    if (mismatchCodeException) {
      return this.renderMismatchedCodeException();
    }

    if (expiredCodeException) {
      return this.renderExpiredCodeException();
    }

    if (token) {
      return this.renderSetPasswordForm();
    }

    if (email) {
      return this.renderRequestSetPasswordTokenForExistingEmail();
    }

    return this.renderRequestSetPasswordToken();
  }

  renderResendVerificationCodeFailed() {
    return (
      <div>
        {this.helmet}
        {this.masthead}
        <Container text textAlign="left" style={{ marginTop: "2em" }}>
          <Segment basic size="huge">
            {this.header}

            <Message
              warning
              icon="warning sign"
              header="Sorry, we could not complete that request"
              content="Your email address has not been verified."
            />

            <p style={{ marginTop: "2em" }}>
              When you recently changed your email address you were sent an email to verify the
              change.
            </p>
            <p>It looks like you did not complete that verification step.</p>
            <p>Please contact our support to verify your email address and reset your password.</p>

            <Grid stackable style={{ marginTop: "2em" }}>
              <Grid.Row>
                <Responsive as={Grid.Column} minWidth={768} width={10} />
                <Grid.Column align="right" verticalAlign="middle" width={6}>
                  <LoaderButton primary fluid size="big" text="Email Support" />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </div>
    );
  }

  renderSetPasswordRequestFailed() {
    const { isLoading } = this.state;

    return (
      <div>
        {this.helmet}
        {this.masthead}
        <Container text textAlign="left" style={{ marginTop: "2em" }}>
          <Segment basic size="huge">
            {this.header}

            <Message
              warning
              icon="warning sign"
              header="Could not complete that request"
              content="The link to set a new password could not be sent."
            />

            <p style={{ marginTop: "2em" }}>
              Please verify you are using the email address associated to an active account.
            </p>

            <Form onSubmit={this.handleCancel} style={{ marginTop: "2em" }}>
              <Grid stackable>
                <Grid.Row>
                  <Responsive as={Grid.Column} minWidth={768} width={10} />
                  <Grid.Column align="right" verticalAlign="middle" width={6}>
                    <LoaderButton
                      primary
                      fluid
                      size="big"
                      isLoading={isLoading}
                      text="Start Over"
                      type="submit"
                      disabled={isLoading}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Segment>
        </Container>
      </div>
    );
  }

  renderSetPasswordTokenRequestSent() {
    const { email } = this.state;

    return (
      <div>
        {this.helmet}
        {this.masthead}
        <Container text textAlign="left" style={{ marginTop: "2em" }}>
          <Segment basic size="huge">
            <Header as="h3">Set new password request sent</Header>
            <p style={{ marginTop: "2em" }}>
              An email containing further instructions has been sent to {email}.
            </p>
          </Segment>
        </Container>
      </div>
    );
  }

  renderVerificationCodeRequest() {
    const { isLoading } = this.state;
    return (
      <div>
        {this.helmet}
        {this.masthead}

        <Container text textAlign="left" style={{ marginTop: "2em" }}>
          <Segment basic size="huge">
            {this.header}

            <Message
              warning
              icon="warning sign"
              header="Email address is unverified"
              content="Your email address has not been verified. Please verify your email."
            />

            <Grid stackable style={{ marginTop: "2em" }}>
              <Grid.Row>
                <Responsive as={Grid.Column} minWidth={768} width={10} />
                <Grid.Column align="right" verticalAlign="middle" width={6}>
                  <LoaderButton
                    primary
                    fluid
                    size="big"
                    isLoading={isLoading}
                    onClick={this.handleRequestEmailVerificationToken}
                    text="Request new verification link"
                    disabled={isLoading}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </div>
    );
  }

  renderVerificationCodeRequestSent() {
    const { email } = this.state;

    return (
      <div>
        {this.helmet}
        {this.masthead}
        <Container text textAlign="left" style={{ marginTop: "2em" }}>
          <Segment basic size="huge">
            <Header as="h3">Email verifiction request sent</Header>

            <p style={{ marginTop: "2em" }}>
              An email containing a link to verify your email address has been sent to {email}.
            </p>
            <p>Please verify your email address first and then return here to set your password.</p>
          </Segment>
        </Container>
      </div>
    );
  }

  renderMismatchedCodeException() {
    const { isLoading } = this.state;
    return (
      <div>
        {this.helmet}
        {this.masthead}

        <Container text textAlign="left" style={{ marginTop: "2em" }}>
          <Segment basic size="huge">
            {this.header}

            <Message
              warning
              icon="warning sign"
              header="Password link is invalid"
              content="The link to set a new password is no longer valid. Please request a new one."
            />

            <Form onSubmit={this.handleSetNewPassword} style={{ marginTop: "2em" }}>
              <Grid stackable>
                <Grid.Row>
                  <Responsive as={Grid.Column} minWidth={768} width={10} />
                  <Grid.Column align="right" verticalAlign="middle" width={6}>
                    <LoaderButton
                      primary
                      fluid
                      size="big"
                      isLoading={isLoading}
                      text="Request new password link"
                      type="submit"
                      disabled={isLoading}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Segment>
        </Container>
      </div>
    );
  }

  renderExpiredCodeException() {
    const { isLoading } = this.state;
    return (
      <div>
        {this.helmet}
        {this.masthead}

        <Container text textAlign="left" style={{ marginTop: "2em" }}>
          <Segment basic size="huge">
            {this.header}

            <Message
              warning
              icon="warning sign"
              header="Password link expired"
              content="The link to set a new password has expired. Please request a new one."
            />

            <Form onSubmit={this.handleSetNewPassword} style={{ marginTop: "2em" }}>
              <Grid stackable>
                <Grid.Row>
                  <Responsive as={Grid.Column} minWidth={768} width={10} />
                  <Grid.Column align="right" verticalAlign="middle" width={6}>
                    <LoaderButton
                      primary
                      fluid
                      size="big"
                      isLoading={isLoading}
                      text="Request new password link"
                      type="submit"
                      disabled={isLoading}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Segment>
        </Container>
      </div>
    );
  }

  renderRequestSetPasswordTokenForExistingEmail() {
    const { isLoading } = this.state;

    return (
      <div>
        {this.helmet}
        {this.masthead}

        <Container text textAlign="left" style={{ marginTop: "2em" }}>
          <Segment basic size="huge">
            {this.header}

            <p>
              Click continue and we'll email you further instructions for setting your password.
            </p>

            <Grid stackable style={{ marginTop: "2em" }}>
              <Grid.Row>
                <Responsive
                  as={Grid.Column}
                  minWidth={768}
                  width={12}
                  verticalAlign="middle"
                  textAlign="right"
                >
                  <SecondaryActionLink>
                    <a href="" onClick={this.handleCancel}>
                      Cancel
                    </a>
                  </SecondaryActionLink>
                </Responsive>
                <Grid.Column textAlign="right" width={4}>
                  <LoaderButton
                    primary
                    fluid
                    onClick={this.handleRequestSetPasswordToken}
                    size="big"
                    isLoading={isLoading}
                    text="Continue"
                    disabled={isLoading}
                  />
                </Grid.Column>
                <Responsive
                  as={Grid.Column}
                  verticalAlign="middle"
                  textAlign="right"
                  maxWidth={767}
                >
                  <SecondaryActionLink>
                    <a href="" onClick={this.handleCancel}>
                      Cancel
                    </a>
                  </SecondaryActionLink>
                </Responsive>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </div>
    );
  }

  renderRequestSetPasswordToken() {
    const { isLoading, isEmailValid, email } = this.state;

    const emailValidationMessage = isEmailValid ? null : (
      <Message
        style={{ marginTop: "2em" }}
        warning
        icon="warning sign"
        header="Please enter a valid email address"
        content="The value you entered does not look like a valid email address."
      />
    );

    return (
      <div>
        {this.helmet}
        {this.masthead}

        <Container text textAlign="left" style={{ marginTop: "2em" }}>
          <Segment basic size="huge">
            {this.header}

            <p>
              Enter your email address and we'll send you further instructions for setting your
              password.
            </p>

            {emailValidationMessage}

            <Form onSubmit={this.handleSubmitRequestSetPasswordToken} style={{ marginTop: "2em" }}>
              <Input
                placeholder="Email address"
                fluid
                focus
                type="text"
                id="emailInput"
                size="huge"
                defaultValue={email ? email : ""}
                onChange={this.handleChange}
                ref={(input) => {
                  this.emailInput = input;
                }}
              />

              <Grid stackable style={{ marginTop: "2em" }}>
                <Grid.Row>
                  <Responsive
                    as={Grid.Column}
                    verticalAlign="middle"
                    textAlign="right"
                    width={12}
                    minWidth={768}
                  >
                    <SecondaryActionLink>
                      <a href="" onClick={this.handleCancel}>
                        Cancel
                      </a>
                    </SecondaryActionLink>
                  </Responsive>
                  <Grid.Column textAlign="right" width={4}>
                    <LoaderButton
                      primary
                      fluid
                      size="big"
                      isLoading={isLoading}
                      text="Continue"
                      disabled={isLoading || !isEmailValid}
                    />
                  </Grid.Column>
                  <Responsive
                    as={Grid.Column}
                    verticalAlign="middle"
                    textAlign="right"
                    maxWidth={767}
                  >
                    <SecondaryActionLink>
                      <a href="" onClick={this.handleCancel}>
                        Cancel
                      </a>
                    </SecondaryActionLink>
                  </Responsive>
                </Grid.Row>
              </Grid>
            </Form>
          </Segment>
        </Container>
      </div>
    );
  }

  renderSetPasswordForm() {
    const { isLoading, isPasswordMatch, isPasswordStrong, email } = this.state;

    const passwordMismatchMessage = isPasswordMatch ? null : (
      <Container text style={{ marginTop: "2em" }}>
        <Message warning icon="warning sign" content="The passwords do not match." />
      </Container>
    );

    const passwordValidationMessage = isPasswordStrong ? null : (
      <Container text style={{ marginTop: "2em" }}>
        <Message
          warning
          icon="warning sign"
          header="Please enter a stronger password"
          content="8 or more characters and must include letters and numbers."
        />
      </Container>
    );

    return (
      <div>
        {this.helmet}
        {this.masthead}

        <Container text textAlign="left" style={{ marginTop: "2em" }}>
          <Segment basic size="huge">
            <Header as="h3">Please enter your new password</Header>

            {passwordMismatchMessage}
            {passwordValidationMessage}

            <p>8 or more characters and must include letters and numbers.</p>

            <Form onSubmit={this.handleSetPassword}>
              <Input
                placeholder="Email address"
                fluid
                focus
                type="hidden"
                id="email"
                name="email"
                size="huge"
                defaultValue={email ? email : ""}
                onChange={this.handleChange}
              />

              <div style={{ marginTop: "2em" }}>
                <Input
                  placeholder="New Password"
                  fluid
                  type="password"
                  id="password"
                  name="password"
                  size="huge"
                  onChange={this.handleChange}
                />
              </div>

              <div style={{ marginTop: "2em" }}>
                <Input
                  placeholder="New Password Again"
                  fluid
                  type="password"
                  id="passwordConfirm"
                  name="passwordConfirm"
                  size="huge"
                  onChange={this.handleChange}
                />
              </div>

              <Grid stackable style={{ marginTop: "2em" }}>
                <Grid.Row>
                  <Responsive as={Grid.Column} width={10} minWidth={768} />
                  <Grid.Column align="right" width={6}>
                    <LoaderButton
                      primary
                      fluid
                      size="big"
                      isLoading={isLoading}
                      text="Set Password"
                      type="submit"
                      disabled={isLoading || !isPasswordMatch || !isPasswordStrong}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Segment>
        </Container>
      </div>
    );
  }
}
