import React, { Component } from 'react';

import { Container, Segment, Header, Item } from 'semantic-ui-react';

import EpisodeItem from './EpisodeItem';

export default class Episodes extends Component {
  render() {
    const {
      episodes,
      showId,
      history,
      isAuthenticated,
      thumbnailSize,
      showActions
    } = this.props;

    const episodeComponents =
      episodes &&
      episodes.map((item, i) => {
        const episodeActions =
          showActions &&
          showActions.filter(actions => {
            return actions.episodeId === item.episodeId;
          });

        const itemProps = {
          ...item,
          showId,
          history,
          isAuthenticated,
          thumbnailSize,
          episodeActions
        };
        return (
          <EpisodeItem index={i + 1} key={item.episodeId} {...itemProps} />
        );
      }, this);

    return (
      <div>
        <Segment basic style={{ marginBottom: '0' }}>
          <Container>
            <Header as="h3">Episodes In This Show</Header>
          </Container>
        </Segment>

        <Segment basic style={{ marginTop: '0' }}>
          <Container>
            <Item.Group>{episodeComponents}</Item.Group>
          </Container>
        </Segment>
      </div>
    );
  }
}
