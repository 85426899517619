import React, { Component } from "react";
import { Item, Segment, Label, Responsive } from "semantic-ui-react";

import "./EpisodeItem.css";
import Text from "./Text";
import { friendlyRunningTime } from "../libs/dateTimeLib";
import { getLabelFromActions } from "../libs/tasksLib";

export default class EpisodeItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };

    this.handleEpisodeClick = this.handleEpisodeClick.bind(this);
  }

  handleEpisodeClick(e) {
    e && e.preventDefault();

    const { history, showId, episodeId, firstChapterId } = this.props;
    const watchpath = `/watch/show/${showId}/episode/${episodeId}/chapter/${firstChapterId}`;

    this.setState({ isLoading: true });

    setTimeout(() => {
      history.push(watchpath);
    }, 200);
  }

  render() {
    const {
      index,
      showId,
      episodeId,
      firstChapterId,
      episodeTitle,
      episodeDescription,
      episodeDurationSeconds,
      thumbnails,
      thumbnailSize,
      episodeActions,
      isAuthenticated,
    } = this.props;

    // for debug/confirming the location of the links, not actually used when clicking the link
    const route = `//watch/show/${showId}/episode/${episodeId}/chapter/${firstChapterId}`;

    let imgSrc;

    try {
      imgSrc = thumbnails[thumbnailSize];
    } catch (e) {
      console.log(`no ${thumbnailSize} thumb`);
    }

    const label = episodeActions && getLabelFromActions(episodeActions);

    const labelProps = label
      ? {
          color: label.color,
          content: `Actions ${label.text}`,
          ribbon: true,
        }
      : null;

    const image = isAuthenticated ? (
      <Item.Image
        onClick={this.handleEpisodeClick}
        src={imgSrc}
        size="medium"
        floated="left"
        className="EpisodeItem-image"
        label={labelProps}
        style={{ cursor: "pointer" }}
      />
    ) : (
      <Item.Image src={imgSrc} size="medium" floated="left" className="EpisodeItem-image" />
    );

    const header = isAuthenticated ? (
      <Item.Header as="h4">
        <a href={route} onClick={this.handleEpisodeClick}>
          {episodeTitle}
        </a>
      </Item.Header>
    ) : (
      <Item.Header as="h4" style={{ color: "#4d4d4d" }}>
        {episodeTitle}
      </Item.Header>
    );

    return (
      <Item className="EpisodeItem">
        <Segment basic compact className="EpisodeItem-segment">
          <Label attached="bottom right" size="huge" className="EpisodeItem-label">
            {friendlyRunningTime(episodeDurationSeconds)}
          </Label>
          {image}
        </Segment>
        <Item.Content>
          {header}
          <Item.Meta>Episode {index}</Item.Meta>
          <Responsive minWidth={768}>
            <Item.Description>
              <Text size="medium">{episodeDescription}</Text>
            </Item.Description>
          </Responsive>
        </Item.Content>
      </Item>
    );
  }
}
