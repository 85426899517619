import React, { Component } from "react";
import {
  Segment,
  Container,
  Header,
  Grid,
  Input,
  Message,
  Form,
  Responsive,
} from "semantic-ui-react";
import { Helmet } from "react-helmet";

import LoaderButton from "../components/LoaderButton";
import MiniMasthead from "../components/MiniMasthead";
import SecondaryActionLink from "../components/SecondaryActionLink";
import { validateEmail } from "../libs/validateEmail";
import { getSubscriptionStatus } from "../libs/apiLib";
import { getUserPool } from "../libs/awsLib";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { parseQueryString } from "../libs/queryStringLib";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: this.props.email ? this.props.email : "",
      password: "",
      redirect: "/",
      isValid: true,
      loginFailed: false,
    };

    this.focusEmailInput = this.focusEmailInput.bind(this);
    this.focusPasswordInput = this.focusPasswordInput.bind(this);
  }

  focusEmailInput() {
    // Explicitly focus the email text input using the raw DOM API
    this.emailInput && this.emailInput.focus();
  }

  focusPasswordInput() {
    // Explicitly focus the password text input using the raw DOM API
    this.passwordInput && this.passwordInput.focus();
  }

  handleChange = (e, data) => {
    // we set state to valid to make any invalid
    // messages go away and to enable the submit button
    // even if the value is not actually valid. We
    // do the actual validation test on submit.
    this.setState({
      [e.target.id]: data.value,
      isValid: true,
      isLoading: false,
    });
  };

  handleSignUp = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.props.history.push("/signup");
    }, 200);
  };

  handleSetNewPassword = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.props.history.push("/setpassword");
    }, 200);
  };

  handleLogin = async (e) => {
    e.preventDefault();

    this.setState({ isLoading: true });

    const { email, password } = this.state;
    const lowercaseEmail = email.toLowerCase();

    const { setAuthenticatedStatus, setEmail, setSubscribedStatus } = this.props;

    const validData = validateEmail(lowercaseEmail) && password.length > 0;

    if (!validData) {
      this.setState({ isValid: false, isLoading: false });
      return;
    }

    var cognitoUser;

    try {
      cognitoUser = await this.login(lowercaseEmail, password);

      // Identify the heap user as an authenticated user by id.
      window.heap && window.heap.identify(cognitoUser.username, "id");
      // Identify the heap user by email address
      window.heap && window.heap.identify(lowercaseEmail, "email");
    } catch (e) {
      console.log("login failed.");
      console.log(e);
      this.setState({ loginFailed: true, isLoading: false });
      return;
    }

    if (!cognitoUser) {
      this.setState({ loginFailed: true, isLoading: false });
      return;
    }

    setAuthenticatedStatus(true);
    setEmail(lowercaseEmail);

    // cognito user should not be null at this point
    // user might not have a subscription, which is ok

    let subscription = null;

    try {
      subscription = await getSubscriptionStatus(cognitoUser.username);
    } catch (e) {
      this.setState({ loginFailed: true, isLoading: false });
    }

    setSubscribedStatus(subscription, () => {
      this.props.history.push(this.state.redirect);
    });
  };

  login(email, password) {
    const userPool = getUserPool();

    const user = new CognitoUser({ Username: email, Pool: userPool });
    const authenticationData = { Username: email, Password: password };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    return new Promise((resolve, reject) =>
      user.authenticateUser(authenticationDetails, {
        onSuccess: (result) => resolve(user),
        onFailure: (err) => reject(err),
      })
    );
  }

  componentDidMount() {
    const { isAuthenticated, email, location } = this.props;

    let redirectLocation = "/";

    if (location && location.search) {
      var parsedQueryParams = parseQueryString(this.props.location.search);

      if (parsedQueryParams && parsedQueryParams.redirect) {
        redirectLocation = decodeURIComponent(parsedQueryParams.redirect);
      }
    }

    // if not authenticated, stash the redirectLocation in state
    // for after authentication.
    if (isAuthenticated) {
      this.props.history.push(redirectLocation);
    } else {
      this.setState({ redirect: redirectLocation });
    }

    if (email) {
      this.focusPasswordInput();
    } else {
      this.focusEmailInput();
    }
  }

  render() {
    const { isLoading, isValid, email, loginFailed } = this.state;
    const { getCanonicalLink } = this.props;

    const validationMessage = isValid ? null : (
      <Message
        style={{ marginTop: "2em" }}
        warning
        icon="warning sign"
        content="Please enter a valid email address and password."
      />
    );

    const loginFailedMessage = loginFailed ? (
      <Message
        style={{ marginTop: "2em" }}
        warning
        icon="warning sign"
        content="Login failed. Please Try again."
      />
    ) : null;

    const helmet = (
      <Helmet>
        <title>Log In | Green Ocean</title>
        <link rel="canonical" href={getCanonicalLink("/login")} />
        <meta
          name="description"
          content="Log in to access your real estate coaching shows and episodes."
        />
      </Helmet>
    );

    return (
      <div>
        {helmet}
        <MiniMasthead text="Log In" />
        <Container text textAlign="left" style={{ marginTop: "2em" }}>
          <Segment basic size="huge">
            <Header as="h3">Log in to access your shows</Header>

            {validationMessage}
            {loginFailedMessage}

            <Form onSubmit={this.handleLogin}>
              <Input
                placeholder="Email address"
                fluid
                focus
                type="text"
                id="email"
                name="email"
                size="huge"
                defaultValue={email ? email : ""}
                onChange={this.handleChange}
                ref={(input) => {
                  this.emailInput = input;
                }}
                style={{ marginTop: "2em" }}
              />

              <Input
                placeholder="Password"
                fluid
                type="password"
                id="password"
                name="password"
                size="huge"
                onChange={this.handleChange}
                ref={(input) => {
                  this.passwordInput = input;
                }}
                style={{ marginTop: "2em" }}
              />

              <Responsive minWidth={768}>
                <Grid style={{ marginTop: "2em" }}>
                  <Grid.Row stretched>
                    <Grid.Column verticalAlign="middle" align="right" width={10}>
                      <SecondaryActionLink>
                        <a href="" onClick={this.handleSetNewPassword}>
                          I don't know my password
                        </a>
                      </SecondaryActionLink>
                    </Grid.Column>
                    <Grid.Column verticalAlign="middle" align="right" width={2}>
                      <SecondaryActionLink>
                        <a href="" onClick={this.handleSignUp}>
                          Sign up
                        </a>
                      </SecondaryActionLink>
                    </Grid.Column>
                    <Grid.Column align="right" verticalAlign="middle" width={4}>
                      <LoaderButton
                        primary
                        fluid
                        size="big"
                        isLoading={isLoading}
                        text="Log in"
                        type="submit"
                        disabled={isLoading || !isValid}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Responsive>

              <Responsive maxWidth={767}>
                <Grid stackable style={{ marginTop: "2em" }}>
                  <Grid.Row>
                    <Grid.Column align="right" verticalAlign="middle">
                      <LoaderButton
                        primary
                        fluid
                        size="big"
                        isLoading={isLoading}
                        text="Log in"
                        type="submit"
                        disabled={isLoading || !isValid}
                      />
                    </Grid.Column>
                    <Grid.Column verticalAlign="middle" align="right">
                      <SecondaryActionLink>
                        <a href="" onClick={this.handleSetNewPassword}>
                          I don't know my password
                        </a>
                      </SecondaryActionLink>
                    </Grid.Column>
                    <Grid.Column verticalAlign="middle" align="right">
                      <SecondaryActionLink>
                        <a href="" onClick={this.handleSignUp}>
                          Sign up
                        </a>
                      </SecondaryActionLink>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Responsive>
            </Form>
          </Segment>
        </Container>
      </div>
    );
  }
}
