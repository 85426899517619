import React, { Component } from "react";
import { Item, Segment, Responsive } from "semantic-ui-react";

import "./ShowItem.css";
import Text from "./Text";
import { getLabelFromTaskStatus } from "../libs/tasksLib";

class ShowItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };

    this.handleShowClick = this.handleShowClick.bind(this);
  }

  handleShowClick(e) {
    e && e.preventDefault();

    const { history, showId, episodeId, chapterId, isAuthenticated } = this.props;

    const watchpath = `/watch/show/${showId}/episode/${episodeId}/chapter/${chapterId}`;
    const showPath = `/show/${showId}`;

    this.setState({ isLoading: true });

    setTimeout(() => {
      history.push(isAuthenticated ? watchpath : showPath);
    }, 200);
  }

  render() {
    const {
      showName,
      showDescription,
      thumbnails,
      thumbnailSize,
      showActions,
      episodeCount,
    } = this.props;

    let imgSrc;

    try {
      imgSrc = thumbnails[thumbnailSize];
    } catch (e) {
      console.log(`no ${thumbnailSize} thumb`);
    }

    const label = showActions && getLabelFromTaskStatus(showActions.status);

    const labelProps = label && {
      color: label.color,
      content: `Actions ${label.text}`,
      ribbon: true,
    };

    return (
      <Item className="ShowItem">
        <Segment basic compact className="ShowItem-segment">
          <Item.Image
            onClick={this.handleShowClick}
            src={imgSrc}
            size="medium"
            floated="left"
            className="ShowItem-image"
            label={labelProps}
          />
        </Segment>
        <Item.Content>
          <Item.Header as="h4">
            <a onClick={this.handleShowClick}>{showName}</a>
          </Item.Header>
          <Item.Meta>{episodeCount} Episodes</Item.Meta>
          <Responsive minWidth={768}>
            <Item.Description>
              <Text size="medium">{showDescription}</Text>
            </Item.Description>
          </Responsive>
        </Item.Content>
      </Item>
    );
  }
}

export default ShowItem;
