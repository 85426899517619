import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Segment, Container } from "semantic-ui-react";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import MiniMasthead from "../components/MiniMasthead";
import CreditCardForm from "../components/CreditCardForm";
import config from "../config";
import { getCoupon } from "../libs/apiLib";

const stripePromise = loadStripe(config.stripe.API_KEY);

export default class Subscribe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      coupon: null,
      isTrial: false,
      isReady: false
    };
  }

  async componentDidMount() {
    const { getCouponCodeFromLocalStorage, getIsTrialOffered, getTrialEndDate } = this.props;

    const status = { isReady: true };
    const isTrial = getIsTrialOffered();

    if (isTrial) {
      status.isTrial = isTrial;
      status.trialEndUtc = getTrialEndDate();
    }

    const couponCode = getCouponCodeFromLocalStorage();

    if (couponCode) {
      try {
        const coupon = await getCoupon(couponCode);

        if (coupon) {
          status.coupon = {
            code: coupon.code,
            initialPayment: coupon.initialPayment,
            subsequentPayment: coupon.subsequentPayment,
          };
        }
      } catch (e) {
        console.log("no coupon with that Id found.");
      }
    }

    this.setState({ ...status });
  }

  render() {
    const { isReady } = this.state;
    const childProps = {
      ...this.props,
      ...this.state,
      update: false,
    };

    const { getCanonicalLink } = this.props;

    const helmet = (
      <Helmet>
        <title>Subscribe | Green Ocean</title>
        <link rel="canonical" href={getCanonicalLink("/subscribe")} />
      </Helmet>
    );

    return (
      <div>
        {helmet}
        <MiniMasthead text="Subscribe" />

        <Container text textAlign="left">
          <Segment basic size="huge">
            <Elements stripe={stripePromise}>
              <ElementsConsumer>
                {({ stripe, elements }) => (
                  !isReady ? null : (
                    <CreditCardForm stripe={stripe} elements={elements} {...childProps} />
                  )
                )}
              </ElementsConsumer>
            </Elements>
          </Segment>
        </Container>
      </div>
    );
  }
}
