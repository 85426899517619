import React from 'react';

import { Segment, Container, Image } from 'semantic-ui-react';

import './ImageMasthead.css';

export default ({ src, handleRecommendedClick }) => {
  return (
    <Segment inverted vertical className="ImageMasthead-background">
      <Container>
        <Image
          src={src}
          onClick={handleRecommendedClick}
          fluid
          className="ImageMasthead-shadow"
        />
      </Container>
    </Segment>
  );
};
