import React from 'react';

import { Button, Icon } from 'semantic-ui-react';

export default ({
  isLoading,
  text,
  loadingText,
  iconName,
  disabled = false,
  ...props
}) => (
  <Button disabled={disabled || isLoading} loading={isLoading} {...props}>
    {text}
    {iconName ? <Icon name={iconName} /> : null}
  </Button>
);
