import React, { Component } from "react";
import { Segment, Container, Header, Grid, Responsive } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import LoaderButton from "../components/LoaderButton";
import MiniMasthead from "../components/MiniMasthead";
import SecondaryActionLink from "../components/SecondaryActionLink";
import { signOutUser, getCurrentUser } from "../libs/awsLib";
import { getSubscriptionDetail } from "../libs/apiLib";

export default class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      subscriptionStatusCode: null,
      ccLastFour: null,
      currentPeriodEnd: null,
      isTrial: false,
      trialEnd: null,
    };
  }

  handleLogout = (e) => {
    e.preventDefault();

    const { unsetEmail, setAuthenticatedStatus, setSubscribedStatus, history } = this.props;

    // resets a user's identity in heap analytics to a random anonymous user ID
    window.heap && window.heap.resetIdentity();

    setAuthenticatedStatus(false);
    setSubscribedStatus(false);

    // history push must be done in a state change callback
    unsetEmail(() => {
      signOutUser();
      history.push("/login");
    });
  };

  handleCancelSubscription = (e) => {
    e.preventDefault();
    this.props.history.push("/cancelsubscription");
  };

  handleChangeEmail = (event) => {
    this.props.history.push("/setemail");
  };

  handleChangePassword = (event) => {
    this.props.history.push("/setpassword");
  };

  handleManageSubscription = (event) => {
    this.props.history.push(
      this.props.isSubscribed || this.props.isCancelled ? "/updatesubscription" : "/subscribe"
    );
  };

  async componentDidMount() {
    const cognitoUser = getCurrentUser();

    try {
      const subscription = await getSubscriptionDetail(cognitoUser.username);

      if (!subscription) {
        return;
      }

      const status = {
        subscriptionStatusCode: subscription.statusCode,
        isTrial: subscription.isTrial,
      };

      if (subscription.trialEndUtc) {
        status.trialEndUtc = subscription.trialEndUtc;
      }

      if (subscription.cardOnFile && subscription.cardOnFile.lastFour) {
        status.ccLastFour = subscription.cardOnFile.lastFour;
      }

      if (subscription.currentPeriodEndUtc) {
        status.currentPeriodEndUtc = subscription.currentPeriodEndUtc;
      }

      this.setState({ ...status });
    } catch (e) {
      console.log("get subscription detail failed.");
      console.log(e);
    }
  }

  render() {
    const { email, isSubscribed, getCanonicalLink } = this.props;

    const {
      isLoading,
      ccLastFour,
      subscriptionStatusCode,
      currentPeriodEndUtc,
      isTrial,
      trialEndUtc,
    } = this.state;

    const trialStatus = isTrial ? "trial " : null;

    const trialEndMessage =
      isTrial && trialEndUtc
        ? ` that will convert to a paid subscription on ${new Date(
            trialEndUtc
          ).toLocaleDateString()}`
        : null;

    const activeSubscriptionDetails =
      isSubscribed && subscriptionStatusCode === 1 ? (
        <p>
          You have an active {trialStatus}
          subscription
          {trialEndMessage}. {ccLastFour ? `Card number on file: **** ${ccLastFour}` : null}
        </p>
      ) : null;

    const pastDueSubscriptionDetails =
      isSubscribed && subscriptionStatusCode === 2 ? (
        <p>
          Your subscription is past due, pending cancellation. You can activate your subscription by
          updating your credit card details.
        </p>
      ) : null;

    const pendingSubscriptionDetails =
      isSubscribed && subscriptionStatusCode === 4 ? (
        <p>
          Your {trialStatus}
          subscription is pending cancellation.{" "}
          {currentPeriodEndUtc
            ? ` It will expire on ${new Date(currentPeriodEndUtc).toLocaleDateString()}. `
            : null}
          You can restart your subscription by updating your credit card details.
        </p>
      ) : null;

    const inactiveSubscriptionDetails = !isSubscribed ? (
      <p>
        Your do not have an active subscription. You can activate your subscription by updating your
        credit card details.
      </p>
    ) : (
      <p />
    );

    const helmet = (
      <Helmet>
        <title>Manage Account | Green Ocean</title>
        <link rel="canonical" href={getCanonicalLink("/account")} />
      </Helmet>
    );

    return (
      <div>
        {helmet}
        <MiniMasthead text="Account" />

        <Container text textAlign="left" style={{ marginTop: "2em" }}>
          <Segment basic size="huge">
            <Header as="h3">Personal</Header>

            <p>You are logged in as {email}.</p>

            <Grid stackable>
              <Grid.Row style={{ marginTop: "2em" }}>
                <Responsive
                  minWidth={768}
                  as={Grid.Column}
                  verticalAlign="middle"
                  textAlign="right"
                  width={4}
                >
                  <SecondaryActionLink>
                    <a href="" onClick={this.handleLogout}>
                      Log Out
                    </a>
                  </SecondaryActionLink>
                </Responsive>

                <Grid.Column verticalAlign="middle" textAlign="right" width={6}>
                  <LoaderButton
                    size="big"
                    onClick={this.handleChangePassword}
                    isLoading={isLoading}
                    text="Change Password"
                    fluid
                  />
                </Grid.Column>
                <Grid.Column verticalAlign="middle" textAlign="right" width={6}>
                  <LoaderButton
                    size="big"
                    onClick={this.handleChangeEmail}
                    isLoading={isLoading}
                    text="Change Email"
                    fluid
                  />
                </Grid.Column>

                <Responsive
                  maxWidth={767}
                  as={Grid.Column}
                  verticalAlign="middle"
                  textAlign="right"
                  width={4}
                >
                  <SecondaryActionLink>
                    <a href="" onClick={this.handleLogout}>
                      Log Out
                    </a>
                  </SecondaryActionLink>
                </Responsive>
              </Grid.Row>
            </Grid>
          </Segment>

          <Segment basic size="huge" style={{ marginTop: "2em" }}>
            <Header as="h3">Subscription</Header>

            {activeSubscriptionDetails}
            {pendingSubscriptionDetails}
            {pastDueSubscriptionDetails}
            {inactiveSubscriptionDetails}

            <Grid stackable>
              <Grid.Row style={{ marginTop: "2em" }}>
                <Responsive
                  as={Grid.Column}
                  minWidth={768}
                  verticalAlign="middle"
                  textAlign="right"
                  width={10}
                >
                  {isSubscribed && subscriptionStatusCode !== 4 /*pending cancellation */ ? (
                    <SecondaryActionLink>
                      <a href="" onClick={this.handleCancelSubscription}>
                        Cancel Subscription
                      </a>
                    </SecondaryActionLink>
                  ) : null}
                </Responsive>
                <Grid.Column verticalAlign="middle" textAlign="right" width={6}>
                  <LoaderButton
                    type="submit"
                    size="big"
                    onClick={this.handleManageSubscription}
                    isLoading={isLoading}
                    text={isSubscribed ? "Update Card" : "Subscribe"}
                    fluid
                  />
                </Grid.Column>
                <Responsive
                  maxWidth={767}
                  as={Grid.Column}
                  verticalAlign="middle"
                  textAlign="right"
                >
                  {isSubscribed && subscriptionStatusCode !== 4 /*pending cancellation */ ? (
                    <SecondaryActionLink>
                      <a href="" onClick={this.handleCancelSubscription}>
                        Cancel Subscription
                      </a>
                    </SecondaryActionLink>
                  ) : null}
                </Responsive>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </div>
    );
  }
}
