import generatePassword from 'password-generator';

const minLength = 8;
const maxLength = 12;
const alphaMinCount = 1;
// const uppercaseMinCount = 0;
// const lowercaseMinCount = 1;
const numberMinCount = 1;
//const specialMinCount = 0;
// const UPPERCASE_RE = /([A-Z])/g;
// const LOWERCASE_RE = /([a-z])/g;
const ALPHA_RE = /([A-Za-z])/g;
// eslint-disable-next-line
const NUMBER_RE = /([\d])/g;
// eslint-disable-next-line
// const SPECIAL_CHAR_RE = /([\?\-])/g;

export function isStrongEnough(password) {
  if (!password) {
    return false;
  }

  // const uppers = password.match(UPPERCASE_RE) ? password.match(UPPERCASE_RE) : 0;
  // const lowers = password.match(LOWERCASE_RE) ? password.match(LOWERCASE_RE) : 0;
  // const specials = password.match(SPECIAL_CHAR_RE) ? password.match(SPECIAL_CHAR_RE) : 0;

  const alphas = password.match(ALPHA_RE) ? password.match(ALPHA_RE).length : 0;

  const numbers = password.match(NUMBER_RE)
    ? password.match(NUMBER_RE).length
    : 0;

  return (
    password.length >= minLength &&
    alphas >= alphaMinCount &&
    numbers >= numberMinCount
  );
}

export function randomPassword() {
  var password = '';
  const randomLength =
    Math.floor(Math.random() * (maxLength - minLength)) + minLength;
  while (!isStrongEnough(password)) {
    // eslint-disable-next-line
    password = generatePassword(randomLength, false, /[\w\d\?\-]/);
  }
  return password;
}
