import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import LoadingSpinner from '../components/LoadingSpinner';

import UpNext from '../components/UpNext';

import Recommended from '../components/Recommended';

import { getCurrentUser } from '../libs/awsLib';

import {
  getContentUserUpNext,
  getContentUserNextShows,
  getContentUserShows,
  getUserActions
} from '../libs/apiLib';

class Homepage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      upNext: null,
      recommended: null,
      userActions: null
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    const cognitoUser = getCurrentUser();

    let upNext;
    let userActions;

    try {
      const task1 = getContentUserUpNext(cognitoUser.username);
      const task2 = getUserActions(cognitoUser.username);

      [upNext, userActions] = await Promise.all([task1, task2]);

      this.setState({ upNext, userActions });
    } catch (e) {
      console.log(e);
    }

    try {
      const recommended = upNext
        ? await getContentUserNextShows(cognitoUser.username, upNext.showId)
        : await getContentUserShows(cognitoUser.username);
      this.setState({ isLoading: false, recommended: recommended });
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { isLoading, upNext, recommended, userActions } = this.state;

    const { getCanonicalLink } = this.props;

    const upNextProps = { ...this.props, ...upNext };

    const upNextMarkup = upNext && <UpNext {...upNextProps} />;

    const recommendedProps = {
      ...this.props,
      ...recommended,
      userActions
    };

    const recommendedMarkup = recommended && (
      <Recommended {...recommendedProps} />
    );

    const helmet = (
      <Helmet>
        <title>Now Playing | Green Ocean</title>
        <link rel="canonical" href={getCanonicalLink('/')} />
      </Helmet>
    );

    if (isLoading) {
      return (
        <div>
          {helmet}
          <LoadingSpinner text="Getting shows..." />
        </div>
      );
    }

    return (
      <div>
        {helmet}
        {upNextMarkup}
        {recommendedMarkup}
      </div>
    );
  }
}

export default Homepage;
