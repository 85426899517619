import React, { Component } from "react";
import { Segment, Container, Header, Form, Input, Message, Grid, Image } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import ReactPixel from "react-facebook-pixel";

import LoaderButton from "../components/LoaderButton";
import MiniMasthead from "../components/MiniMasthead";
import Text from "../components/Text";
import { validateEmail } from "../libs/validateEmail";
import { randomPassword } from "../libs/passwords";
import { AuthenticationDetails } from "amazon-cognito-identity-js";
import { getUserPool, publishAccountAction } from "../libs/awsLib";
import { createAccountPersona } from "../libs/apiLib";
import config from "../config";
import * as TrackingEvents from "../libs/trackingEvents.js";

import "./SignUp.css";

export default class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      isLoading: false,
      isValid: true,
      inUse: false,
      hasError: false,
    };

    this.focusEmailInput = this.focusEmailInput.bind(this);
  }

  focusEmailInput() {
    // Explicitly focus the email text input using the raw DOM API
    this.emailInput && this.emailInput.focus();
  }

  handleChange = (e, data) => {
    // we set state to valid to make any invalid
    // messages go away and to enable the submit button
    // even if the value is not actually valid. We
    // do the actual validation test on submit.
    this.setState({
      [e.target.id]: data.value,
      isValid: true,
      isLoading: false,
      inUse: false,
    });
  };

  handleLogin = async (e) => {
    e.preventDefault();

    this.setState({ isLoading: true });

    setTimeout(() => {
      this.props.history.push("/login");
    }, 200);
  };

  handleCancel = async (e) => {
    e.preventDefault();

    this.setState({ isLoading: true });

    setTimeout(() => {
      this.props.history.push("/persona");
    }, 200);
  };

  componentWillMount() {
    const { isAuthenticated } = this.props;

    if (isAuthenticated) {
      this.props.history.push("/account");
      return;
    }
  }

  componentDidMount() {
    this.focusEmailInput();

    try {
      ReactPixel.track("InitiateCheckout");
    } catch (e) {
      console.log(e);
    }
  }

  handleContinue = async (e) => {
    e.preventDefault();

    let { setAuthenticatedStatus } = this.props;
    const { email } = this.state;
    const lowercaseEmail = email.toLowerCase();
    const validData = lowercaseEmail && validateEmail(lowercaseEmail);

    if (!validData) {
      this.setState({ isValid: false, isLoading: false });
      return;
    }

    this.setState({ isLoading: true });

    const password = randomPassword();
    let cognitoUser = null;

    try {
      cognitoUser = await this.signup(lowercaseEmail, password);
    } catch (e) {
      console.log(e);

      if (e && e.code && e.code === "UsernameExistsException") {
        this.props.setEmail(lowercaseEmail);
        this.setState({ isLoading: false, inUse: true });
        return;
      }

      this.setState({ isLoading: false, hasError: true });

      window.heap &&
        window.heap.track(TrackingEvents.EXCEPTION_SIGNUP, {
          lowercaseEmail,
        });

      return;
    }

    this.props.setEmail(lowercaseEmail);

    try {
      await this.authenticate(cognitoUser, lowercaseEmail, password);
    } catch (e) {
      console.log(e);

      this.setState({ isLoading: false, hasError: true });

      window.heap &&
        window.heap.track(TrackingEvents.EXCEPTION_SIGNUP_AUTHENTICATE, {
          lowercaseEmail,
          exception: e,
        });

      return;
    }

    const persona = {
      accountId: cognitoUser.username,
      personaId: config.defaultPersona.ID,
    };

    try {
      await createAccountPersona(persona);
    } catch (e) {
      console.log(e);

      this.setState({ isLoading: false, hasError: true });

      window.heap &&
        window.heap.track(TrackingEvents.EXCEPTION_CREATE_PERSONA, {
          lowercaseEmail,
          exception: e,
        });

      return;
    }

    try {
      await this.publishNewUserMessage(persona);
    } catch (e) {
      console.log(e);

      this.setState({ isLoading: false, hasError: true });

      window.heap &&
        window.heap.track(TrackingEvents.EXCEPTION_PUBLISH_SIGNUP, {
          lowercaseEmail,
        });

      return;
    }

    try {
      await setAuthenticatedStatus(true);
    } catch (e) {
      console.log(e);

      this.setState({ isLoading: false, hasError: true });

      window.heap &&
        window.heap.track(TrackingEvents.EXCEPTION_SIGNUP_STATUS, {
          lowercaseEmail,
        });

      return;
    }

    // Identify the heap user as an authenticated user by id.
    window.heap && window.heap.identify(cognitoUser.username, "id");
    // Identify the heap user by email address
    window.heap && window.heap.identify(lowercaseEmail, "email");

    // Next page is payment page
    this.props.history.push("/subscribe");
  };

  signup(email, password) {
    const userPool = getUserPool();

    return new Promise((resolve, reject) =>
      userPool.signUp(email, password, [], null, (err, result) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result.user);
      })
    );
  }

  authenticate(user, email, password) {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    return new Promise((resolve, reject) =>
      user.authenticateUser(authenticationDetails, {
        onSuccess: (result) => resolve(),
        onFailure: (err) => {
          console.log(err);
          reject(err);
        },
      })
    );
  }

  async publishNewUserMessage(persona) {
    await publishAccountAction(persona.accountId, persona.personaId, "CreateAcknowleged");
  }

  render() {
    const { isLoading, isValid, hasError, inUse } = this.state;
    const { getCanonicalLink } = this.props;

    const isValidMessage = isValid ? null : (
      <Message
        style={{ marginTop: "2em" }}
        warning
        icon="warning sign"
        header="Please enter a valid email address"
        content="The value you entered does not look like a valid email address."
      />
    );

    const inUseMessage = inUse ? (
      <Message
        style={{ marginTop: "2em" }}
        warning
        icon="warning sign"
        header="Email address already in use"
        content="The value you entered is already taken. Please try another."
      />
    ) : null;

    const hasErrorMessage = hasError ? (
      <Message
        style={{ marginTop: "2em" }}
        warning
        icon="warning sign"
        header="Error creating your account"
        content="Sorry, there was an error when creating your account. Please try again."
      />
    ) : null;

    const helmet = (
      <Helmet>
        <title>Sign Up For Free | Green Ocean</title>
        <link rel="canonical" href={getCanonicalLink("/signup")} />
        <meta name="description" content="Sign up for a free trial of GO shows and episodes." />
      </Helmet>
    );

    return (
      <div>
        {helmet}
        <MiniMasthead text="Get Started!" />

        <Container text textAlign="center" style={{ marginTop: "2em", marginBottom: "3em" }}>
          <Segment basic size="huge">
            <Header as="h3">Pay nothing. Watch anything.</Header>

            <p>Your 7-day free trial starts here. No restrictions. Cancel whenever you want.</p>

            {isValidMessage}
            {inUseMessage}
            {hasErrorMessage}

            <Form onSubmit={this.handleContinue}>
              <Input
                placeholder="Email address"
                error={!isValid}
                fluid
                focus
                type="text"
                id="email"
                name="email"
                size="huge"
                onChange={this.handleChange}
                ref={(input) => {
                  this.emailInput = input;
                }}
                style={{ marginTop: "2em" }}
              />

              <Grid stackable style={{ marginTop: "2em" }}>
                <Grid.Row>
                  <Grid.Column verticalAlign="middle" textAlign="center" width={12}>
                    <p>
                      By clicking 'Continue' you are agreeing to our{" "}
                      <a href="/privacypolicy" target="_blank">
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </Grid.Column>
                  <Grid.Column textAlign="right" width={4}>
                    <LoaderButton
                      primary
                      fluid
                      size="big"
                      isLoading={isLoading}
                      text="Continue"
                      loadingText="Loading…"
                      icon
                      disabled={isLoading || !isValid || inUse}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Segment>
        </Container>

        <Segment basic className="SignUp-Segment-Padded SignUp-Segment-Grey">
          <Container className="SignUp-Container-Narrow">
            <Grid stackable columns={3}>
              <Grid.Row>
                <Grid.Column>
                  <p>
                    <Image src="https://res.cloudinary.com/green-ocean/image/upload/f_auto,q_auto/v1591472424/presenters/jason-00.jpg" />
                  </p>
                  <Text size="medium">Marketing &amp; Branding</Text>
                </Grid.Column>
                <Grid.Column>
                  <p>
                    <Image src="https://res.cloudinary.com/green-ocean/image/upload/f_auto,q_auto/v1591472424/presenters/hien-00.jpg" />
                  </p>
                  <Text size="medium">Leadership Development</Text>
                </Grid.Column>
                <Grid.Column>
                  <p>
                    <Image src="https://res.cloudinary.com/green-ocean/image/upload/f_auto,q_auto/v1591472424/presenters/adam-00.jpg" />
                  </p>
                  <Text size="medium">Lead Generation</Text>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </div>
    );
  }
}
