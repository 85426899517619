import React, { Component } from "react";
import { Segment, Container } from "semantic-ui-react";
import { getCurrentUser, publishContentAction } from "../libs/awsLib";
import { getAuthorizedChapter } from "../libs/apiLib";
import * as TrackingEvents from "../libs/trackingEvents.js";
import "./Player.css";
import "./VideoMasthead.css";
import config from "../config";

export default class Player extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: null,
      authorizing: true,
      contentTopicArn: null,
      showId: null,
      episodeId: null,
      chapterId: null,
    };

    this.fetchAuthorizedChapterToken = this.fetchAuthorizedChapterToken.bind(this);

    this.attachPlayer = this.attachPlayer.bind(this);
    this.disposePlayer = this.disposePlayer.bind(this);
    this.iframeOnLoad = this.iframeOnLoad.bind(this);
  }

  async fetchAuthorizedChapterToken(id, callback) {
    const cognitoUser = getCurrentUser();

    this.setState({ authorizing: true });

    try {
      const authorizedChapter = await getAuthorizedChapter(cognitoUser.username, id);
      this.setState({ token: authorizedChapter.token, authorizing: false }, callback);
    } catch (e) {
      console.log(e);
      this.setState({ authorizing: false }, callback);
    }
  }

  videoPlayed = (event, data) => {
    const location = this.props.getCurrentLocation();

    if (!location) {
      return;
    }

    const cognitoUser = getCurrentUser();

    const { showId, episodeId, chapterId } = location;

    window.heap &&
      window.heap.track(TrackingEvents.CLICK_PLAY_CHAPTER, {
        showId,
        episodeId,
        chapterId,
      });

    publishContentAction(
      cognitoUser.username,
      showId,
      episodeId,
      chapterId /* chapter id as int */,
      "Watched"
    );
  };

  videoPaused = (event, data) => {
    const location = this.props.getCurrentLocation();

    if (!location) {
      return;
    }

    const { showId, episodeId, chapterId } = location;

    window.heap &&
      window.heap.track(TrackingEvents.CLICK_PAUSE_CHAPTER, {
        showId,
        episodeId,
        chapterId,
      });
  };

  videoSeeked = (event, data) => {
    const location = this.props.getCurrentLocation();

    if (!location) {
      return;
    }

    const { showId, episodeId, chapterId } = location;

    window.heap &&
      window.heap.track(TrackingEvents.CLICK_SEEK_CHAPTER, {
        showId,
        episodeId,
        chapterId,
      });
  };

  videoResumed = (event, data) => {
    const location = this.props.getCurrentLocation();

    if (!location) {
      return;
    }

    const { showId, episodeId, chapterId } = location;

    window.heap &&
      window.heap.track(TrackingEvents.CLICK_RESUME_CHAPTER, {
        showId,
        episodeId,
        chapterId,
      });
  };

  videoEnded = async (event, data) => {
    const location = this.props.getCurrentLocation();

    if (!location) {
      return;
    }

    const cognitoUser = getCurrentUser();

    const { showId, episodeId, chapterId } = location;

    window.heap &&
      window.heap.track(TrackingEvents.VIDEO_ENDED, {
        showId,
        episodeId,
        chapterId,
      });

    publishContentAction(
      cognitoUser.username,
      showId,
      episodeId,
      chapterId /* chapter id as int */,
      "Finished"
    );

    if (config.player.autoAdvance) {
      await this.props.getNextVideo(location);
    }
  };

  disposePlayer = () => {
    if (!this.player) {
      return;
    }

    this.player.dispose();

    delete this.player;
  };

  iframeOnLoad = () => {
    const { setPlayerEventsBound, getPlayerEventsBound } = this.props;

    if (!window.VHX) {
      console.log("VHX not present, exiting");
      return;
    }

    try {
      this.player = new window.VHX.Player("video");
    } catch (e) {
      console.log("error initializing window.VHX.Player");
      console.log(e);
    }

    if (!this.player) {
      return;
    }

    if (!getPlayerEventsBound()) {
      this.player.on("play", this.videoPlayed);
      this.player.on("ended", this.videoEnded);
      this.player.on("pause", this.videoPaused);
      this.player.on("seeked", this.videoSeeked);
      this.player.on("resume", this.videoResumed);
      setPlayerEventsBound(true);
    }
  };

  // Attaching the player using DOM manipulation
  // after componentDidMount() to work around
  // chrome cross origin exceptions when manipulating
  // the iframe src in render().
  // Unfortunately, this means we have to destroy and recreate the
  // player and events on every new video play.
  attachPlayer() {
    const { chapterId, autoplay } = this.props;

    const { token } = this.state;

    var videoContainer = document.querySelector("#video-container");

    if (!videoContainer) {
      console.log("#video-container not found in DOM, can't attach iframe");
      return;
    }

    let iframe = document.querySelector("#video");

    if (iframe) {
      videoContainer.removeChild(iframe);
    }

    iframe = document.createElement("iframe");
    iframe.id = "video";
    iframe.onload = this.iframeOnLoad;
    iframe.width = "1128";
    iframe.height = "634";
    iframe.frameBorder = "0";
    iframe.allowFullscreen = true;
    iframe.allow = "autoplay; fullscreen";
    iframe.src = `https://embed.vhx.tv/videos/${chapterId}?sharing=0&title=0&mylist=0&api=1&autoplay=${
      autoplay && config.player.autoPlay ? 1 : 0
    }&authorization=${token}`;

    videoContainer.appendChild(iframe);
  }

  async componentDidMount() {
    const { showId, episodeId, chapterId } = this.props;

    // put showId, episodeId, chapterId in state so that player events always use latest state and not props
    this.setState({
      showId,
      episodeId,
      chapterId,
    });

    await this.fetchAuthorizedChapterToken(chapterId, () => {
      this.attachPlayer();
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log('Player::shouldComponentUpdate');
    // we do not want state changes to ever cause a re-render
    // becuase we manage the iframe manually
    return false;
  }

  render() {
    return (
      <Segment inverted vertical className="VideoMasthead-background">
        <Container>
          <div className="Player-video-container VideoMasthead-shadow">
            <div id="video-container" />
          </div>
        </Container>
      </Segment>
    );
  }

  componentWillUnmount() {
    this.disposePlayer();
  }
}
