import { invokeApig } from "./awsLib";
import config from "../config";

export async function getSurveyQuestion(path) {
  return invokeApig({
    endpoint: config.surveyEndpoint.URL,
    path: `/${path ? path : ""}`,
  });
}

export async function getPersona(path) {
  return invokeApig({
    endpoint: config.personaEndpoint.URL,
    path: `/${path ? path : ""} `,
  });
}

export async function createAccountPersona(body) {
  return invokeApig({
    endpoint: config.userEndpoint.URL,
    path: `/${body.accountId}/persona`,
    method: "POST",
    body: body,
    expectResponse: true,
    signed: true,
  });
}

export async function getSubscriptionStatus(cognitoUsername) {
  return invokeApig({
    endpoint: config.subscriptionsEndpoint.URL,
    path: `/${cognitoUsername}/status`,
    method: "GET",
    signed: true,
  });
}

export async function getSubscriptionDetail(cognitoUsername) {
  return invokeApig({
    endpoint: config.subscriptionsEndpoint.URL,
    path: `/${cognitoUsername}/detail`,
    method: "GET",
    signed: true,
  });
}

export async function createSubscription(body) {
  return invokeApig({
    endpoint: config.subscriptionsEndpoint.URL,
    path: "/",
    method: "POST",
    body: body,
    expectResponse: false,
    signed: true,
  });
}

export async function updateSubscription(cognitoUsername, body) {
  return invokeApig({
    endpoint: config.subscriptionsEndpoint.URL,
    path: `/${cognitoUsername}`,
    method: "PUT",
    body: body,
    expectResponse: false,
    signed: true,
  });
}

export async function updateCoupon(cognitoUsername, body) {
  return invokeApig({
    endpoint: config.subscriptionsEndpoint.URL,
    path: `/${cognitoUsername}/coupon`,
    method: "PUT",
    body: body,
    expectResponse: false,
    signed: true,
  });
}

export async function deleteSubscription(cognitoUsername) {
  return invokeApig({
    endpoint: config.subscriptionsEndpoint.URL,
    path: `/${cognitoUsername}`,
    method: "DELETE",
    expectResponse: false,
    signed: true,
  });
}

export async function getContentPersonaShows(personaId, maxItems = 10) {
  return invokeApig({
    endpoint: config.contentEndpoint.URL,
    path: `/persona/${personaId}/shows?max=${maxItems}`,
    method: "GET",
    signed: false,
  });
}

export async function getContentUserShows(username) {
  return invokeApig({
    endpoint: config.userEndpoint.URL,
    path: `/${username}/shows`,
    method: "GET",
    signed: true,
  });
}

export async function getContentUserUpNext(username) {
  return invokeApig({
    endpoint: config.userEndpoint.URL,
    path: `/${username}/whatsnext`,
    method: "GET",
    signed: true,
  });
}

export async function getContentUserNextShows(username, showId) {
  return invokeApig({
    endpoint: config.userEndpoint.URL,
    path: `/${username}/show/${showId}/nextshows`,
    method: "GET",
    signed: true,
  });
}

export async function getChapterDetail(showId, episodeId, chapterId) {
  return invokeApig({
    endpoint: config.contentEndpoint.URL,
    path: `/show/${showId}/episode/${episodeId}/chapter/${chapterId}/detail`,
    method: "GET",
    signed: false,
  });
}

export async function getChapters(showId, episodeId) {
  return invokeApig({
    endpoint: config.contentEndpoint.URL,
    path: `/show/${showId}/episode/${episodeId}/chapters`,
    method: "GET",
    signed: false,
  });
}

export async function getEpisodes(showId) {
  return invokeApig({
    endpoint: config.contentEndpoint.URL,
    path: `/show/${showId}/episodes`,
    method: "GET",
    signed: false,
  });
}

export async function getShow(showId) {
  return invokeApig({
    endpoint: config.contentEndpoint.URL,
    path: `/show/${showId}`,
    method: "GET",
    signed: false,
  });
}

export async function getAllShows() {
  return invokeApig({
    endpoint: config.contentEndpoint.URL,
    path: `/shows`,
    method: "GET",
    signed: false,
  });
}

export async function getAuthorizedChapter(username, chapterId) {
  return invokeApig({
    endpoint: config.userEndpoint.URL,
    path: `/${username}/authorize/${chapterId}`,
    method: "GET",
    signed: true,
  });
}

export async function getCoupon(couponCode) {
  return invokeApig({
    endpoint: config.couponEndpoint.URL,
    path: `/${couponCode}`,
    method: "GET",
    signed: false,
  });
}

export async function getSalesTaxRate(street, city, state, zip, amount) {
  let query = `?amount=${encodeURIComponent(amount)}&street=${encodeURIComponent(
    street
  )}&city=${encodeURIComponent(city)}&state=${encodeURIComponent(
    state
  )}&postalCode=${encodeURIComponent(zip)}`;

  return invokeApig({
    endpoint: config.salesTaxEndpoint.URL,
    path: query,
    method: "GET",
    signed: false,
  });
}

export async function postContactForm(body) {
  return invokeApig({
    endpoint: config.contactEndpoint.URL,
    path: `/`,
    method: "POST",
    body: body,
    expectResponse: false,
    signed: false,
  });
}

export async function getChapterActions(username, chapterId) {
  return invokeApig({
    endpoint: config.tasksEndpoint.URL,
    path: `/tasks/user/${username}/chapter/${chapterId}`,
    method: "GET",
    signed: true,
  });
}

export async function getShowActions(username, showId) {
  return invokeApig({
    endpoint: config.tasksEndpoint.URL,
    path: `/tasks/user/${username}/show/${showId}`,
    method: "GET",
    signed: true,
  });
}

export async function getUserActions(username) {
  return invokeApig({
    endpoint: config.tasksEndpoint.URL,
    path: `/tasks/user/${username}`,
    method: "GET",
    signed: true,
  });
}

export async function postChapterActionTask(body) {
  return invokeApig({
    endpoint: config.tasksEndpoint.URL,
    path: `/tasks/usertask`,
    method: "POST",
    body: body,
    expectResponse: true,
    signed: true,
  });
}

export async function postEmailListSignup(body) {
  return invokeApig({
    endpoint: config.marketingEndpoint.URL,
    path: `/subscribe`,
    method: "POST",
    body: body,
    expectResponse: false,
    signed: false,
  });
}
