import React, { Component } from 'react';

import { Grid, Responsive, Header, Image } from 'semantic-ui-react';

import './CouponOfferPromo.css';

import LoaderButton from './LoaderButton';

import Text from './Text';

export default class CouponOfferPromo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  handleGetStarted = async e => {
    e.preventDefault();

    const { setCouponCodeInLocalStorage, couponCode } = this.props;

    this.setState({ isLoading: true });

    if (couponCode) {
      setCouponCodeInLocalStorage(couponCode);
    }

    setTimeout(() => {
      this.props.history.push('/signup');
    }, 200);

    return;
  };

  render() {
    const { isLoading } = this.state;

    return (
      <Grid stackable inverted padded className="CouponOfferPromo">
        <Grid.Row>
          <Grid.Column width={10}>
            <Header as="h3" inverted>
              50% off your first month!
            </Header>
            <Text size="large">
              Get access to the marketing and technology strategies you need to
              gain the competitive edge in this new real estate paradigm.
            </Text>
            <Text size="large" />
            <Responsive
              as={LoaderButton}
              style={{ marginTop: '2rem' }}
              primary
              size="huge"
              text="Let's GO!"
              loadingText="Loading…"
              isLoading={isLoading}
              disabled={isLoading}
              minWidth={768}
              onClick={this.handleGetStarted}
            />
            <Responsive
              as={LoaderButton}
              style={{ marginTop: '2rem' }}
              primary
              fluid
              size="huge"
              text="Let's GO!"
              loadingText="Loading…"
              isLoading={isLoading}
              disabled={isLoading}
              maxWidth={767}
              onClick={this.handleGetStarted}
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <Image
              src="https://res.cloudinary.com/green-ocean/image/upload/q_auto/f_auto/c_scale,w_500/v1526345360/web/women-against-wall.jpg"
              size="medium"
              circular
              floated="right"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
