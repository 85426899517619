import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import { Container, Image, Segment, Grid } from 'semantic-ui-react';

import MiniMasthead from '../components/MiniMasthead';

import LoadingSpinner from '../components/LoadingSpinner';

import LoaderButton from '../components/LoaderButton';

import Episodes from '../components/Episodes';

import Text from '../components/Text';

import { getEpisodes, getShow } from '../libs/apiLib';

export default class Show extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showId: null,
      episodes: null,
      show: null,
      isLoading: true
    };

    this.fetchShowData = this.fetchShowData.bind(this);
    this.fetchEpisodesData = this.fetchEpisodesData.bind(this);

    this.handleWatchNowClick = this.handleWatchNowClick.bind(this);
  }

  async fetchEpisodesData(showId, episodeId) {
    const episodes = await getEpisodes(showId);

    this.setState({ episodes });
  }

  async fetchShowData(showId) {
    const show = await getShow(showId);

    this.setState({ show });
  }

  async componentDidMount() {
    // this.props.match.params are all strings;

    let paramsAsInts = {};

    Object.keys(this.props.match.params).forEach(key => {
      paramsAsInts[key] = parseInt(this.props.match.params[key], 10);
    });

    const { showId } = paramsAsInts;

    this.setState({
      showId: showId,
      isLoading: true
    });

    try {
      await this.fetchShowData(showId);
      await this.fetchEpisodesData(showId);
    } catch (e) {
      console.log(e);
    }

    this.setState({
      isLoading: false
    });
  }

  handleWatchNowClick(e) {
    e && e.preventDefault();

    const { history, isAuthenticated } = this.props;

    this.setState({ isLoading: true });

    const { episodes, showId } = this.state;

    const episodeId =
      episodes &&
      episodes.episodes &&
      episodes.episodes[0] &&
      episodes.episodes[0].episodeId;

    const chapterId = episodeId && episodes.episodes[0].firstChapterId;

    // should really check chapterId is defined before using it below

    const route = `/watch/show/${showId}/episode/${episodeId}/chapter/${chapterId}`;

    setTimeout(() => {
      history.push(isAuthenticated ? route : '/signup');
    }, 200);
  }

  render() {
    const {
      history,
      isAuthenticated,
      thumbnailSize,
      getCanonicalLink
    } = this.props;

    const { showId, show, episodes, isLoading } = this.state;

    const episodesProps = Object.assign(
      {},
      {
        showId,
        history,
        isAuthenticated,
        thumbnailSize
      },
      { ...episodes }
    );

    const canonical = getCanonicalLink(`/show/${showId}`);

    if (isLoading && !episodes) {
      return (
        <div>
          <Helmet>
            <title>Show - Description and Episodes | Green Ocean</title>
            <link rel="canonical" href={canonical} />
          </Helmet>
          <LoadingSpinner text="Loading show ..." />
        </div>
      );
    }

    const pageTitle = `${
      show.showName
    } - Description and Episodes | Green Ocean`;

    return (
      <div>
        <Helmet>
          <title>{pageTitle}</title>
          <link rel="canonical" href={canonical} />
          <meta name="description" content={show.showDescription} />
        </Helmet>

        <MiniMasthead text={show.showName} />

        <Segment basic>
          <Container>
            <Image fluid src={show.thumbnails.large} />
          </Container>
        </Segment>

        <Segment basic>
          <Container>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Text size="large">{show.showDescription}</Text>
                </Grid.Column>
                <Grid.Column width={4} textAlign="center">
                  <LoaderButton
                    primary
                    fluid
                    size="huge"
                    isLoading={isLoading}
                    text={isAuthenticated ? 'Watch Now' : 'Try For Free'}
                    loadingText="Loading…"
                    disabled={isLoading}
                    onClick={this.handleWatchNowClick}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>

        <Episodes {...episodesProps} />
      </div>
    );
  }
}
