import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import { Segment, Embed, Container, Header } from 'semantic-ui-react';

import EmailListPromo from '../components/EmailListPromo';

import CouponOfferPromo from '../components/CouponOfferPromo';

import Text from '../components/Text';

export default class AdOnboarding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videoId: null,
      isShowingPromo: true,
      isShowingOffer: false
    };
  }

  componentDidMount() {
    const { videoId, action } = this.props.match.params;

    const isShowingPromo = action && action === 'signup';

    const isShowingOffer = action && action === 'offer';

    this.setState({
      videoId: videoId,
      isShowingPromo: isShowingPromo,
      isShowingOffer: isShowingOffer
    });
  }

  render() {
    const { videoId, isShowingPromo, isShowingOffer } = this.state;

    const placeholderImageUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;

    const couponPromoProps = { couponCode: 'go-50', ...this.props };

    return (
      <div>
        <Helmet>
          <title>Watch For Free - Try Now | Green Ocean</title>
        </Helmet>

        <Container>
          <Segment basic>
            <Embed
              id={videoId}
              source="youtube"
              placeholder={placeholderImageUrl}
              style={{ marginTop: '2rem' }}
              iframe={{
                allowFullScreen: true
              }}
            />
          </Segment>

          <Segment basic>
            <Header as="h2">
              Ask yourself, what’s the cost if you don’t get coached?
            </Header>
            <Text size="large">
              Sometimes you just need a little direction, inspiration, and
              coaching both in life and in business. Do the GO action items and
              you WILL succeed in real estate.
            </Text>
          </Segment>

          <Segment basic>
            {isShowingOffer && <CouponOfferPromo {...couponPromoProps} />}
            {isShowingPromo && <EmailListPromo />}
          </Segment>
        </Container>
      </div>
    );
  }
}
