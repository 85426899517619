import { CognitoUserPool } from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk/global';
import SNS from 'aws-sdk/clients/sns';
import config from '../config';
import sigV4Client from './sigV4Client';

// const DEBUG = process.env.NODE_ENV !== 'production';

const DEBUG = false;

export async function invokeApig({
  endpoint,
  path,
  method = 'GET',
  headers = {},
  queryParams = {},
  body,
  expectResponse = true,
  signed = false
}) {
  if (signed && !(await authUser())) {
    throw new Error(
      'Cognito user needs to be logged in to call authorized endpoints using signed headers'
    );
  }

  let url = `${endpoint}${path}`;

  if (DEBUG) {
    console.log(`invokeApig ${url}`);
  }

  const rawBody = body;

  body = body ? JSON.stringify(body) : body;

  if (method === 'POST' || (method === 'PUT' && !Object.keys(headers).length)) {
    headers = {
      'Content-Type': 'application/json'
    };
  }

  let signedRequest;

  if (signed) {
    signedRequest = sigV4Client
      .newClient({
        accessKey: AWS.config.credentials.accessKeyId,
        secretKey: AWS.config.credentials.secretAccessKey,
        sessionToken: AWS.config.credentials.sessionToken,
        region: config.apiGateway.REGION,
        endpoint: endpoint
      })
      .signRequest({
        method,
        path,
        headers,
        queryParams,
        body: rawBody // sigV4Client will call JSON.stringify on this
      });

    headers = signedRequest.headers;

    url = signedRequest.url;

    if (DEBUG) {
      console.log(`singed request url: ${signedRequest.url}`);
      console.log(`singed headers: ${JSON.stringify(headers)}`);
    }
  }

  const response = await fetch(url, {
    method,
    headers,
    body,
    mode: 'cors'
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }

  // 204 = request serviced, but no data found
  if (response.status === 204) {
    return {};
  }

  if (expectResponse) {
    const res = await response.json();
    if (DEBUG) {
      console.log(res);
    }
    return res;
  }

  return {};
}

export async function authUser() {
  if (
    AWS.config.credentials &&
    Date.now() < AWS.config.credentials.expireTime - 60000
  ) {
    return true;
  }

  const currentUser = getCurrentUser();

  if (currentUser === null) {
    return false;
  }

  const userToken = await getUserToken(currentUser);

  await getAwsCredentials(userToken);

  return true;
}

export function getUserToken(currentUser) {
  return new Promise((resolve, reject) => {
    currentUser.getSession(function(err, session) {
      if (err) {
        reject(err);
        return;
      }
      resolve(session.getIdToken().getJwtToken());
    });
  });
}

export function getUserPool() {
  return new CognitoUserPool({
    UserPoolId: config.cognito.USER_POOL_ID,
    ClientId: config.cognito.APP_CLIENT_ID
  });
}

export function getCurrentUser() {
  const userPool = getUserPool();
  return userPool.getCurrentUser();
}

export function signOutUser() {
  const currentUser = getCurrentUser();

  if (currentUser !== null) {
    currentUser.signOut();
  }

  if (AWS.config.credentials) {
    AWS.config.credentials.clearCachedId();
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({});
  }
}

function getAwsCredentials(userToken) {
  const authenticator = `cognito-idp.${config.cognito.REGION}.amazonaws.com/${
    config.cognito.USER_POOL_ID
  }`;

  AWS.config.update({ region: config.cognito.REGION });

  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
    Logins: {
      [authenticator]: userToken
    }
  });

  return AWS.config.credentials.getPromise();
}

export function getUserAttribute(cognitoUser, attribute) {
  return new Promise((resolve, reject) => {
    if (!attribute) {
      reject('no attribute given');
    }

    cognitoUser.getUserAttributes((err, result) => {
      if (err) {
        reject(err);
        return;
      }

      let value;

      for (let i = result.length - 1; i >= 0; i = i - 1) {
        if (result[i].getName() === 'email') {
          value = result[i].getValue();
          break;
        }
      }

      resolve(value);
    });
  });
}

export function resendVerificationCode(cognitoUser) {
  return new Promise((resolve, reject) => {
    cognitoUser.getAttributeVerificationCode('email', {
      onSuccess: result => resolve(),
      onFailure: err => reject(err),
      inputVerificationCode: null
    });
  });
}

export async function publishSubscriptionAction(accountId, email, eventType) {
  let status;

  if (!accountId) {
    console.log(
      'publishSubscriptionAction::accountId for SNS publish is not set, not publishing'
    );
    return;
  }

  if (!email) {
    console.log(
      'publishSubscriptionAction::email for SNS publish is not set, not publishing'
    );
    return;
  }

  if (!eventType) {
    console.log(
      'publishSubscriptionAction::eventType for SNS publish is not set, not publishing'
    );
    return;
  }

  switch (eventType) {
    case 'CreateAcknowleged':
      status = 'Active';
      break;

    case 'UpdateAcknowleged':
      status = 'Active';
      break;

    case 'DeleteRequested':
      status = 'CancellationPending';
      break;

    default:
      break;
  }

  if (!eventType || !status) {
    console.log(
      'publishSubscriptionAction::eventType or Status unknown, not publishing'
    );
    return;
  }

  const body = {
    accountId,
    status,
    emailAddress: email
  };

  try {
    return await publishSns(
      config.sns.SUBSCRIPTIONS_TOPIC_ARN,
      eventType,
      body
    );
  } catch (e) {
    console.log('publish sns failed.');
    console.log(e);
  }
}

export async function publishAccountAction(
  accountId,
  personaId,
  eventType,
  options = null
) {
  if (!accountId) {
    console.log(
      'publishAccountAction::accountId for SNS publish is not set, not publishing'
    );
    return;
  }

  if (!eventType) {
    console.log(
      'publishAccountAction::eventType for SNS publish is not set, not publishing'
    );
    return;
  }

  if (eventType !== 'CreateAcknowleged' && eventType !== 'UpdateAcknowleged') {
    console.log(
      "publishAccountAction::eventType for SNS publish is neither 'CreateAcknowleged' or 'UpdateAcknowleged', not publishing"
    );
    return;
  }

  // Topic: account-events
  // Message:
  // Expecting event type (in attributes dictionary): CreateAcknowleged, UpdateAcknowleged
  // Message Body:
  // {
  //     "accountId" : "045caf71-0a8f-4406-ad65-86c04954ee12",
  //     "personaId" : 2
  // }

  const body = Object.assign(
    {},
    {
      accountId,
      personaId
    },
    options ? { ...options } : {}
  );

  try {
    return await publishSns(config.sns.ACCOUNTS_TOPIC_ARN, eventType, body);
  } catch (e) {
    console.log('publish sns failed.');
    console.log(e);
  }
}

export async function publishContentAction(
  accountId,
  showId,
  episodeId,
  chapterId,
  eventType
) {
  if (!accountId) {
    console.log(
      'publishContentAction::accountId for SNS publish is not set, not publishing'
    );
    return;
  }

  if (!showId) {
    console.log(
      'publishContentAction::showId for SNS publish is not set, not publishing'
    );
    return;
  }

  if (!episodeId) {
    console.log(
      'publishContentAction::episodeId for SNS publish is not set, not publishing'
    );
    return;
  }

  if (!chapterId) {
    console.log(
      'publishContentAction::chapterId for SNS publish is not set, not publishing'
    );
    return;
  }

  if (!eventType) {
    console.log(
      'publishContentAction::eventType for SNS publish is not set, not publishing'
    );
    return;
  }

  if (eventType !== 'Watched' && eventType !== 'Finished') {
    console.log(
      "publishAccountAction::eventType for SNS publish is not 'Watched' or 'Finished', not publishing"
    );
    return;
  }

  // Topic: content-events
  // Message:
  // Expecting event type (in attributes dictionary): Watched
  // Message Body:
  // {
  //         "accountId " : "045caf71-0a8f-4406-ad65-86c04954ee12",
  //         "showId" : 40637,
  //         "episodeId" :46998,
  //         "chapterId" : 234588
  // }

  const body = {
    accountId,
    showId,
    episodeId,
    chapterId
  };

  try {
    return await publishSns(config.sns.CONTENT_TOPIC_ARN, eventType, body);
  } catch (e) {
    console.log('publish sns failed.');
    console.log(e);
  }
}

export async function publishSns(topicArn, eventType, body) {
  return new Promise(async (resolve, reject) => {
    if (!(await authUser())) {
      reject('User is not logged in');
    }

    const sns = new SNS();

    const attributes = {
      Event: {
        StringValue: eventType,
        DataType: 'String'
      },
      Timestamp: {
        StringValue: Math.round(Date.now() / 1000) + '',
        DataType: 'String'
      },
      Source: {
        StringValue: 'web-client',
        DataType: 'String'
      }
    };

    const params = {
      Message: JSON.stringify(body),
      MessageAttributes: attributes,
      TargetArn: topicArn
    };

    if (DEBUG) {
      console.log(JSON.stringify(params));
    }

    sns.publish(params, (err, data) => {
      if (err) {
        reject(err);
        return;
      }

      resolve(data);
    });
  });
}
