import React, { Component } from "react";
import { Helmet } from "react-helmet";
import MiniMasthead from "../components/MiniMasthead";
import LoadingSpinner from "../components/LoadingSpinner";
import ChapterDetail from "../components/ChapterDetail";
import Chapters from "../components/Chapters";
import Episodes from "../components/Episodes";
import PricingDetail from "../components/PricingDetail";
import { getChapterDetail, getChapters, getEpisodes, getShowActions } from "../libs/apiLib";
import { getCurrentUser } from "../libs/awsLib";
import * as TrackingEvents from "../libs/trackingEvents.js";

export default class Watch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showId: null,
      episodeId: null,
      chapterId: null,
      chapterDetail: null,
      chapters: null,
      episodes: null,
      isLoading: true,
      autoplay: false,
      chapterActions: null,
      showActions: null,
      username: null,
    };

    this.fetchEpisodesData = this.fetchEpisodesData.bind(this);
    this.fetchChapterData = this.fetchChapterData.bind(this);
    this.fetchShowTasks = this.fetchShowTasks.bind(this);
    this.handlePartClicked = this.handlePartClicked.bind(this);
    this.getNextVideo = this.getNextVideo.bind(this);
  }

  async fetchEpisodesData(showId, episodeId) {
    const episodes = await getEpisodes(showId);
    const chapters = await getChapters(showId, episodeId);

    this.setState({ episodes, chapters });
  }

  async fetchChapterData(showId, episodeId, chapterId) {
    this.setState({
      showId,
      episodeId,
      chapterId,
    });

    const chapterDetail = await getChapterDetail(showId, episodeId, chapterId);
    this.setState({ chapterDetail });
  }

  async fetchShowTasks(showId, chapterId) {
    const cognitoUser = getCurrentUser();

    try {
      const showActions = await getShowActions(cognitoUser.username, showId);

      if (!showActions || !Array.isArray(showActions)) {
        this.setState({
          chapterActions: null,
          showActions: null,
        });
        return;
      }

      const chapterActions = showActions.find((actions) => {
        return actions.chapterId === chapterId;
      });

      if (!chapterActions) {
        this.setState({
          chapterActions: null,
          showActions,
        });
        return;
      }

      this.setState({
        username: cognitoUser.username,
        chapterActions,
        showActions,
      });
    } catch (e) {
      console.log("get chapter tasks failed.");
      console.log(e);
      this.setState({
        chapterActions: null,
      });
    }
  }

  handlePartClicked(chapterId) {
    const { showId, episodeId } = this.state;

    window.heap &&
      window.heap.track(TrackingEvents.CLICK_CHAPTER_PART, {
        showId,
        episodeId,
        chapterId,
      });

    this.props.history.push(`/watch/show/${showId}/episode/${episodeId}/chapter/${chapterId}`);
  }

  getCurrentLocation = () => {
    const matches = window.location.href.match(/show\/(\d+)\/episode\/(\d+)\/chapter\/(\d+)/);

    if (!matches) {
      return null;
    }

    return {
      showId: parseInt(matches[1], 10),
      episodeId: parseInt(matches[2], 10),
      chapterId: parseInt(matches[3], 10),
    };
  };

  // we can not use this.state in
  // getNextVideo becuase it is bound the first
  // time a video plays. We need to figure out current
  // state every invocation.
  async getNextVideo(location) {
    let { showId, episodeId, chapterId } = location;

    const { history } = this.props;

    // is the current video the 1st or 2nd part in the current chapter?

    const chapterDetail = await getChapterDetail(showId, episodeId, chapterId);

    if (!chapterDetail || !chapterDetail.chapters) {
      return;
    }

    let nextChapterId = null;

    let nextEpisodeId = episodeId;

    // if we are on part 1 and there is a part 2
    if (chapterDetail.chapters[0].id === chapterId && chapterDetail.chapters[1]) {
      nextChapterId = chapterDetail.chapters[1].id;
    } else {
      // we are  on a part 2. Or there is no part 2.
      // find the chapter that comes after the id of the * part 1 *
      // of the current chapter regardless of whether we are on part 1 or part 2
      // because the getChapters API call only returns the chapter Ids
      // of part 1s, and we need the chapter that comes after the

      const episode = await getChapters(showId, episodeId);

      const watchedChapterId = chapterDetail.chapters[0].id;

      let i = 0;
      let j = episode && episode.chapters ? episode.chapters.length : 0;

      while (!nextChapterId && i < j) {
        if (episode.chapters[i].id === watchedChapterId && episode.chapters[i + 1]) {
          nextChapterId = episode.chapters[i + 1].id;
        }
        i++;
      }
    }

    // if no next chapter, get the next episode
    if (!nextChapterId) {
      const show = await getEpisodes(showId);

      const watchedEpisodeId = episodeId;

      let m = 0;
      let n = show.episodes ? show.episodes.length : 0;
      let foundNextEpisodeId = null;

      while (!foundNextEpisodeId && m < n) {
        if (show.episodes[m].episodeId === watchedEpisodeId && show.episodes[m + 1]) {
          foundNextEpisodeId = show.episodes[m + 1].episodeId;
          nextChapterId = show.episodes[m + 1].firstChapterId;
        }
        m++;
      }

      if (foundNextEpisodeId) {
        nextEpisodeId = foundNextEpisodeId;
      }
    }

    if (nextChapterId && nextEpisodeId) {
      window.heap &&
        window.heap.track(TrackingEvents.ADVANCE_TO_NEXT_CHAPTER, {
          showId: showId,
          episodeId: nextEpisodeId,
          chapterId: nextChapterId,
        });

      // the /1 at the end of the URL denotes autoplay=true
      const route = `/watch/show/${showId}/episode/${nextEpisodeId}/chapter/${nextChapterId}/1`;
      setTimeout(() => {
        history.push(route);
      }, 200);
    }
  }

  async componentDidMount() {
    const { isSubscribed } = this.props;

    let paramsAsInts = {};

    Object.keys(this.props.match.params).forEach((key) => {
      paramsAsInts[key] = parseInt(this.props.match.params[key], 10);
    });

    const { showId, episodeId, chapterId, autoplay } = paramsAsInts;

    this.setState({
      isLoading: true,
      autoplay: !!autoplay,
    });

    // not subscribed, go straight to render and don't make any API calls
    if (!isSubscribed) {
      this.setState({
        isLoading: false,
      });
      return;
    }

    try {
      // these first two call different endpoints
      const task1 = this.fetchEpisodesData(showId, episodeId);
      const task2 = this.fetchShowTasks(showId, chapterId);

      await Promise.all([task1, task2]);

      await this.fetchChapterData(showId, episodeId, chapterId);
    } catch (e) {
      console.log(e);
    }

    this.setState({
      isLoading: false,
    });
  }

  async componentWillReceiveProps(nextProps) {
    const { showId, episodeId, chapterId } = this.state;

    const nextShowId = parseInt(nextProps.match.params.showId, 10);
    const nextEpisodeId = parseInt(nextProps.match.params.episodeId, 10);
    const nextChapterId = parseInt(nextProps.match.params.chapterId, 10);
    const autoplay = nextProps.match.params.autoplay;

    if (nextShowId === showId && nextEpisodeId === episodeId && nextChapterId === chapterId) {
      return;
    }

    this.setState({
      isLoading: true,
      autoplay: !!autoplay,
    });

    // if new show or new episode then reload episodes and chapters
    if (nextShowId !== showId || nextEpisodeId !== episodeId) {
      try {
        // these first two call different endpoints
        const task1 = this.fetchEpisodesData(nextShowId, nextEpisodeId);
        const task2 = this.fetchShowTasks(nextShowId, nextChapterId);

        await Promise.all([task1, task2]);

        await this.fetchChapterData(nextShowId, nextEpisodeId, nextChapterId);
      } catch (e) {
        console.log(e);
      }

      this.setState({
        isLoading: false,
      });

      return;
    }

    // only chapter has changed, fetch new chapter detail and refresh player
    if (nextChapterId !== chapterId) {
      try {
        // these first two call different endpoints
        const task1 = this.fetchChapterData(nextShowId, nextEpisodeId, nextChapterId);
        const task2 = this.fetchShowTasks(nextShowId, nextChapterId);

        await Promise.all([task1, task2]);
      } catch (e) {
        console.log(e);
      }
    }

    this.setState({
      isLoading: false,
    });
  }

  handleActionsClicked = async () => {
    const { showId, chapterId } = this.state;

    try {
      await this.fetchShowTasks(showId, chapterId);
    } catch (e) {
      console.log(e);
    }
  };

  handleSubscribeClicked = (e) => {
    e.preventDefault();

    // link to the signup page with the current watch location as the redirect
    const signupPath = `/signup?redirect=${encodeURIComponent(this.props.location.pathname)}`;

    this.setState({
      isLoading: true,
    });

    setTimeout(() => {
      this.props.history.push(signupPath);
    }, 300);
  };

  handleContactUsClicked = (e) => {
    e.preventDefault();

    setTimeout(() => {
      this.props.history.push("/contactus");
    }, 300);
  };

  render() {
    const {
      isSubscribed,
      isAuthenticated,
      history,
      playerEventsBound,
      setPlayerEventsBound,
      getPlayerEventsBound,
      thumbnailSize,
    } = this.props;

    const {
      showId,
      episodeId,
      chapterId,
      chapterDetail,
      chapters,
      episodes,
      isLoading,
      autoplay,
      chapterActions,
      showActions,
      username,
    } = this.state;

    if (isLoading) {
      return (
        <div>
          <Helmet>
            <title>Watch | Green Ocean</title>
          </Helmet>
          <LoadingSpinner text="Loading show..." />
        </div>
      );
    }

    if (!isSubscribed) {
      const pricingProps = {
        handleSubscribeClicked: this.handleSubscribeClicked,
        handleContactUsClicked: this.handleContactUsClicked,
        buttonText: "Subscribe",
      };

      return (
        <div>
          <Helmet>
            <title>Try For Free - Agent and Office Plans | Green Ocean</title>
          </Helmet>
          <MiniMasthead text="Subscribe" />
          <PricingDetail {...pricingProps} />
        </div>
      );
    }

    const chapterDetailProps = {
      showId,
      episodeId,
      chapterId,
      chapters,
      handlePartClicked: this.handlePartClicked,
      getNextVideo: this.getNextVideo,
      playerEventsBound,
      setPlayerEventsBound,
      getPlayerEventsBound,
      getCurrentLocation: this.getCurrentLocation,
      autoplay,
      chapterActions,
      handleActionsClicked: this.handleActionsClicked,
      username,
      ...chapterDetail,
    };

    let chaptersMarkup = null;

    if (chapters && chapters.chapters && chapters.chapters.length > 1) {
      const chaptersProps = {
        showId,
        episodeId,
        chapters,
        history,
        thumbnailSize,
        chapterIndex: chapterDetail && chapterDetail.chapterIndex,
        ...chapters,
      };

      chaptersMarkup = <Chapters {...chaptersProps} />;
    }

    const episodesProps = {
      showId,
      history,
      isAuthenticated,
      thumbnailSize,
      showActions,
      ...episodes,
    };

    const pageTitle = `Watch ${chapterDetail.episodeName} - ${chapterDetail.showName} | Green Ocean`;

    return (
      <div>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <ChapterDetail {...chapterDetailProps} />
        {chaptersMarkup}
        <Episodes {...episodesProps} />
      </div>
    );
  }
}
