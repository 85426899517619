import React, { Component } from 'react';

import {
  Grid,
  Input,
  Responsive,
  Header,
  Image,
  Form,
  Transition,
  Message
} from 'semantic-ui-react';

import './EmailListPromo.css';

import { validateEmail } from '../libs/validateEmail';

import { postEmailListSignup } from '../libs/apiLib';

import LoaderButton from './LoaderButton';

import Text from './Text';

export default class EmailListPromo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      isLoading: false,
      isValid: true,
      hasError: false,
      didSubmitForm: false
    };
  }

  handleChange = (e, data) => {
    // we set state to valid to make any invalid
    // messages go away and to enable the submit button
    // even if the value is not actually valid. We
    // do the actual validation test on submit.
    this.setState({
      [e.target.id]: data.value,
      isValid: true,
      isLoading: false
    });
  };

  handleFormSubmit = async e => {
    e.preventDefault();

    const { email } = this.state;

    const validData = email && validateEmail(email);

    if (!validData) {
      this.setState({ isValid: false, isLoading: false });
      return;
    }

    this.setState({ isLoading: true });

    try {
      await postEmailListSignup({
        emailAddress: email
      });
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false });
      return;
    }

    this.setState({ isValid: true, isLoading: false, didSubmitForm: true });
  };

  render() {
    const { isValid, isLoading, didSubmitForm } = this.state;

    const isValidMessage = isValid ? null : (
      <Message
        style={{ marginTop: '2em' }}
        warning
        icon="warning sign"
        header="Please enter a valid email address"
        content="The value you entered does not look like a valid email address."
      />
    );

    const emailSubmitForm = (
      <div>
        {!didSubmitForm && (
          <div>
            <Header as="h3" inverted>
              Not quite ready to subscribe?
            </Header>
            <Text size="large">
              We'll email you full episodes on marketing, technology, sales
              scripts, lead generation and even psychology to give you a
              competitive edge.
            </Text>
            {isValidMessage}
            <Form>
              <Input
                placeholder="Email address"
                error={!isValid}
                fluid
                focus
                type="text"
                id="email"
                name="email"
                size="huge"
                onChange={this.handleChange}
                style={{ marginTop: '2em' }}
              />
              <Responsive
                as={LoaderButton}
                style={{ marginTop: '2rem' }}
                primary
                size="huge"
                text="Send"
                loadingText="Loading…"
                isLoading={isLoading}
                disabled={isLoading || !isValid}
                minWidth={768}
                onClick={this.handleFormSubmit}
              />
              <Responsive
                as={LoaderButton}
                style={{ marginTop: '2rem' }}
                primary
                fluid
                size="huge"
                text="Send"
                loadingText="Loading…"
                isLoading={isLoading}
                disabled={isLoading || !isValid}
                maxWidth={767}
                onClick={this.handleFormSubmit}
              />
            </Form>
          </div>
        )}
      </div>
    );

    const emailSubmitThankyou = (
      <Transition visible={didSubmitForm} duration={600} unmountOnHide>
        <div>
          <Header as="h3" inverted>
            Your access is on its way!
          </Header>
          <Text size="large">
            Your first full episode will arrive in the next day or so, with more
            to follow.
          </Text>
          <Text size="large">
            We'll also let you know when we release new shows and episodes.
          </Text>
        </div>
      </Transition>
    );

    return (
      <Grid stackable inverted padded className="EmailListPromo">
        <Grid.Row>
          <Grid.Column width={10}>
            {emailSubmitForm}
            {emailSubmitThankyou}
          </Grid.Column>
          <Grid.Column width={6}>
            <Image
              src="https://res.cloudinary.com/green-ocean/image/upload/c_crop,f_auto,h_836,q_auto,w_836,x_250,y_0/v1531979429/web/pondering-man.jpg"
              size="medium"
              circular
              floated="right"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
