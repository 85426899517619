import React from 'react';

import { Segment, Dimmer, Loader } from 'semantic-ui-react';

export default ({ text }) => (
  <Segment basic padded="very" size="huge" style={{ marginTop: '2em' }}>
    <Dimmer active inverted>
      <Loader size="huge">{text}</Loader>
    </Dimmer>
  </Segment>
);
