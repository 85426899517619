export function getLabelFromTaskStatus(status) {
  switch (status) {
    case "Started":
      return { color: "red", text: "Started" };

    case "Completed":
      return { color: "green", text: "Completed!" };

    default:
      return null;
  }
}

export function getLabelFromActions(actions) {
  const anyStarted = actions.find((a) => {
    return a.status === "Started";
  });

  if (anyStarted) {
    return getLabelFromTaskStatus("Started");
  }

  const allCompleted =
    actions.length &&
    actions.every((a) => {
      return a.status && a.status === "Completed";
    });

  if (allCompleted) {
    return getLabelFromTaskStatus("Completed");
  }

  return null;
}
