import React, { Component } from "react";
import {
  Segment,
  Container,
  Header,
  Grid,
  Input,
  Message,
  Form,
  Dimmer,
  Loader,
  Responsive,
} from "semantic-ui-react";

import { Helmet } from "react-helmet";
import LoaderButton from "../components/LoaderButton";
import MiniMasthead from "../components/MiniMasthead";
import SecondaryActionLink from "../components/SecondaryActionLink";
import { validateEmail } from "../libs/validateEmail";
import {
  authUser,
  getCurrentUser,
  getUserToken,
  getUserAttribute,
  publishAccountAction,
} from "../libs/awsLib";

import { CognitoUserAttribute } from "amazon-cognito-identity-js";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      token: "",
      newEmail: "",
      isEmailValid: true,
      isEmailTaken: false,
      setEmailTokenRequestSent: false,
      setEmailTokenRequestFailed: false,
      setEmailTokenRequestComplete: false,
      expiredCodeException: false,
      mismatchCodeException: false,
    };

    this.masthead = <MiniMasthead text="Email" />;

    this.helmet = (
      <Helmet>
        <title>Change Email Address | Green Ocean</title>
      </Helmet>
    );

    this.focusEmailInput = this.focusEmailInput.bind(this);

    // user could have got here from email link only,
    // unathenticated user will not have email in app state,
    // but might have email in local storage

    // check props first, then local storage

    this.state.email = this.props.email;

    if (!this.state.email) {
      console.log(`get email from local storage ${this.props.getEmailFromLocalStorage()}`);

      // this.state.email could still be empty string after this if
      // not found in local storage
      this.state.email = this.props.getEmailFromLocalStorage();
    }

    if (this.state.email) {
      // lift up state to application, this also puts email into local storage
      this.props.setEmail(this.state.email);
    }
  }

  focusEmailInput() {
    // Explicitly focus the newEmail text input using the raw DOM API
    this.newEmailInput && this.newEmailInput.focus();
  }

  handleChange = (e, data) => {
    // we set state to valid to make any invalid
    // messages go away and to enable the submit button
    // even if the value is not actually valid. We
    // do the actual validation test on submit.
    this.setState({
      [e.target.id]: data.value,
      isEmailValid: true,
      isLoading: false,
    });
  };

  handleCancel = (e) => {
    e.preventDefault();
    this.props.history.push("/account");
  };

  handleOk = (e) => {
    e.preventDefault();
    this.props.history.push("/account");
  };

  handleSetNewEmail = (e) => {
    e.preventDefault();

    this.setState({
      expiredCodeException: false,
      isLoading: false,
      token: null,
    });
  };

  handleContinue = async (e) => {
    e.preventDefault();

    const { newEmail } = this.state;
    const { setEmail } = this.props;

    const lowercaseNewEmail = newEmail.toLowerCase();
    const validData = lowercaseNewEmail && validateEmail(lowercaseNewEmail);

    if (!validData) {
      this.setState({ isEmailValid: false, isLoading: false });
      return;
    }

    this.setState({ isLoading: true });

    try {
      if (await authUser()) {
        const cognitoUser = getCurrentUser();
        await getUserToken(cognitoUser);

        const oldEmail = await getUserAttribute(cognitoUser, "email");

        // this will trigger a verification email
        await this.setEmail(cognitoUser, lowercaseNewEmail);
        // this lifts up the the email address in app state
        // and puts it in our local storage
        setEmail(lowercaseNewEmail);

        // raise the chaned user account event so that
        // any CRM related systems are made aware of the
        // change in email address
        await publishAccountAction(cognitoUser.username, null, "UpdateAcknowleged", {
          oldEmail,
          newEmail,
        });

        this.setState({
          isDone: true,
          isEmailValid: true,
          isEmailTaken: false,
          setEmailTokenRequestSent: true,
          isLoading: false,
        });
      }
    } catch (e) {
      console.log(e);

      if (!e || !e.code) {
        this.setState({ isLoading: false });
        return;
      }

      switch (e.code) {
        case "AliasExistsException":
          this.setState({ isEmailTaken: true, isLoading: false });
          break;
        default:
          this.setState({ setEmailTokenRequestFailed: true, isLoading: false });
          break;
      }
    }
  };

  setEmail(cognitoUser, newEmail) {
    const emailAttribute = new CognitoUserAttribute({
      Name: "email",
      Value: newEmail,
    });

    const attributeList = [emailAttribute];

    return new Promise((resolve, reject) =>
      cognitoUser.updateAttributes(attributeList, (err, result) => {
        if (err) {
          reject(err);
          return;
        }

        resolve(result);
      })
    );
  }

  confirmEmail(cognitoUser, token) {
    return new Promise((resolve, reject) =>
      cognitoUser.verifyAttribute("email", token, {
        onSuccess: (result) => resolve(),
        onFailure: (err) => reject(err),
      })
    );
  }

  async componentDidMount() {
    // capture token in the incoming URL
    await this.setState({ token: this.props.match.params.token });

    const { token } = this.state;

    try {
      if (token && (await authUser())) {
        const cognitoUser = getCurrentUser();
        await getUserToken(cognitoUser);
        await this.confirmEmail(cognitoUser, token);

        this.setState({ setEmailTokenRequestComplete: true, isLoading: false });
      }
    } catch (e) {
      if (!e || !e.code) {
        this.setState({ isLoading: false });
        return;
      }

      switch (e.code) {
        case "CodeMismatchException":
          this.setState({ mismatchCodeException: true, isLoading: false });
          break;
        case "ExpiredCodeException":
          this.setState({ expiredCodeException: true, isLoading: false });
          break;
        default:
          this.setState({ isLoading: false });
          break;
      }
    }

    this.focusEmailInput();
  }

  render() {
    const {
      setEmailTokenRequestFailed,
      setEmailTokenRequestSent,
      setEmailTokenRequestComplete,
      mismatchCodeException,
      expiredCodeException,
      token,
    } = this.state;

    // we render a success message regardless of whether the
    // email is confirmed/verified or not
    if (setEmailTokenRequestFailed || setEmailTokenRequestSent) {
      return this.renderSetEmailTokenRequestSent();
    }

    if (mismatchCodeException) {
      return this.renderMismatchedCodeException();
    }

    if (expiredCodeException) {
      return this.renderExpiredCodeException();
    }

    if (setEmailTokenRequestComplete) {
      return this.renderSetEmailTokenRequestComplete();
    }

    if (token) {
      return this.renderSetEmailTokenRequestInProgress();
    }

    return this.renderRequestSetEmailToken();
  }

  renderSetEmailTokenRequestInProgress() {
    return (
      <div>
        {this.helmet}
        {this.masthead}
        <Segment basic padded="very" size="huge" style={{ marginTop: "2em" }}>
          <Dimmer active inverted>
            <Loader size="huge">Setting email address...</Loader>
          </Dimmer>
        </Segment>
      </div>
    );
  }

  renderSetEmailTokenRequestSent() {
    const { newEmail } = this.state;

    return (
      <div>
        {this.helmet}
        {this.masthead}
        <Container text textAlign="left" style={{ marginTop: "2em" }}>
          <Segment basic size="huge">
            <Header as="h3">Set a new email address</Header>

            <Message
              warning
              icon="warning sign"
              header="Verify your email address"
              content="If you do not verify your email address you may not be able to log in."
            />

            <p style={{ marginTop: "2em" }}>
              An email containing further instructions has been sent to {newEmail}.
            </p>
          </Segment>
        </Container>
      </div>
    );
  }

  renderSetEmailTokenRequestComplete() {
    const { email, isLoading } = this.state;

    return (
      <div>
        {this.helmet}
        {this.masthead}
        <Container text textAlign="left" style={{ marginTop: "2em" }}>
          <Segment basic size="huge">
            <Header as="h3">Email address set</Header>

            <p>Your email address has been verified as {email}.</p>

            <Form onSubmit={this.handleOk} style={{ marginTop: "2em" }}>
              <Grid stackable>
                <Grid.Row>
                  <Responsive as={Grid.Column} width={10} minWidth={768} />
                  <Grid.Column align="right" verticalAlign="middle" width={6}>
                    <LoaderButton
                      primary
                      fluid
                      type="submit"
                      size="big"
                      isLoading={isLoading}
                      text="OK"
                      disabled={isLoading}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Segment>
        </Container>
      </div>
    );
  }

  renderMismatchedCodeException() {
    const { isLoading } = this.state;

    return (
      <div>
        {this.helmet}
        {this.masthead}
        <Container text textAlign="left" style={{ marginTop: "2em" }}>
          <Segment basic size="huge">
            <Header as="h3">Set a new email address</Header>

            <Message
              warning
              icon="warning sign"
              header="Email link is invalid"
              content="The link to set a new email is no longer valid. Please request a new one."
            />

            <Form onSubmit={this.handleSetNewEmail} style={{ marginTop: "2em" }}>
              <Grid stackable>
                <Grid.Row>
                  <Responsive as={Grid.Column} width={10} minWidth={768} />
                  <Grid.Column align="right" verticalAlign="middle" width={6}>
                    <LoaderButton
                      primary
                      fluid
                      size="big"
                      isLoading={isLoading}
                      text="Request new email link"
                      type="submit"
                      disabled={isLoading}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Segment>
        </Container>
      </div>
    );
  }

  renderExpiredCodeException() {
    const { isLoading } = this.state;

    return (
      <div>
        {this.helmet}
        {this.masthead}
        <Container text textAlign="left" style={{ marginTop: "2em" }}>
          <Segment basic size="huge">
            <Header as="h3">Set a new email address</Header>

            <Message
              warning
              icon="warning sign"
              header="Email link expired"
              content="The link to set a new email has expired. Please request a new one."
            />

            <Form onSubmit={this.handleSetNewEmail} style={{ marginTop: "2em" }}>
              <Grid stackable>
                <Grid.Row>
                  <Responsive as={Grid.Column} width={10} minWidth={768} />
                  <Grid.Column align="right" verticalAlign="middle" width={6}>
                    <LoaderButton
                      primary
                      fluid
                      size="big"
                      isLoading={isLoading}
                      text="Request new email link"
                      type="submit"
                      disabled={isLoading}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Segment>
        </Container>
      </div>
    );
  }

  renderRequestSetEmailToken() {
    const { isLoading, isEmailValid, isEmailTaken } = this.state;

    const isEmailValidMessage = isEmailValid ? null : (
      <Message
        style={{ marginTop: "2em" }}
        warning
        icon="warning sign"
        header="Please enter a valid email address"
        content="The value you entered does not look like a valid email address."
      />
    );

    const isEmailTakenMessage = isEmailTaken ? (
      <Message
        style={{ marginTop: "2em" }}
        warning
        icon="warning sign"
        header="Please enter a different email address"
        content="The email address you entered is already in use."
      />
    ) : null;

    return (
      <div>
        {this.helmet}
        {this.masthead}

        <Container text textAlign="left" style={{ marginTop: "2em" }}>
          <Segment basic size="huge">
            <Header as="h3">Set a new email address</Header>

            <p>Enter your new email address and we'll email you a link to verify the change.</p>

            {isEmailValidMessage}
            {isEmailTakenMessage}

            <Form onSubmit={this.handleContinue} style={{ marginTop: "2em" }}>
              <Input
                placeholder="Email address"
                fluid
                focus
                type="text"
                id="newEmail"
                size="huge"
                onChange={this.handleChange}
                ref={(input) => {
                  this.newEmailInput = input;
                }}
              />

              <Grid stackable style={{ marginTop: "2em" }}>
                <Grid.Row>
                  <Responsive
                    as={Grid.Column}
                    verticalAlign="middle"
                    textAlign="right"
                    width={12}
                    minWidth={768}
                  >
                    <SecondaryActionLink>
                      <a href="" onClick={this.handleCancel}>
                        Cancel
                      </a>
                    </SecondaryActionLink>
                  </Responsive>
                  <Grid.Column textAlign="right" width={4}>
                    <LoaderButton
                      primary
                      fluid
                      type="submit"
                      size="big"
                      isLoading={isLoading}
                      text="Continue"
                      disabled={isLoading || !isEmailValid}
                    />
                  </Grid.Column>
                  <Responsive
                    as={Grid.Column}
                    verticalAlign="middle"
                    textAlign="right"
                    maxWidth={767}
                  >
                    <SecondaryActionLink>
                      <a href="" onClick={this.handleCancel}>
                        Cancel
                      </a>
                    </SecondaryActionLink>
                  </Responsive>
                </Grid.Row>
              </Grid>
            </Form>
          </Segment>
        </Container>
      </div>
    );
  }
}
