import React, { Component } from 'react';
import {
  Container,
  Segment,
  Header,
  Item,
  Grid,
  Responsive
} from 'semantic-ui-react';

import ShowItem from './ShowItem';

import LoaderButton from '../components/LoaderButton';

export default class Recommended extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  handleAllShows = async e => {
    e.preventDefault();

    this.setState({ isLoading: true });

    setTimeout(() => {
      this.props.history.push('/shows');
    }, 200);

    return;
  };

  render() {
    const {
      content,
      history,
      isAuthenticated,
      thumbnailSize,
      userActions
    } = this.props;

    const { isLoading } = this.state;

    const recommendedComponents =
      content &&
      content.map((item, i) => {
        const showActions =
          userActions &&
          userActions.showRollups &&
          userActions.showRollups.find(actions => {
            return actions.showId === item.showId;
          });

        const itemProps = {
          ...item,
          history,
          isAuthenticated,
          thumbnailSize,
          showActions
        };

        return <ShowItem key={item.episodeId} {...itemProps} />;
      }, this);

    return (
      <div>
        <Segment basic style={{ marginTop: '2rem', marginBottom: '0' }}>
          <Container>
            <Header as="h3">Popular Shows</Header>
          </Container>
        </Segment>

        <Segment basic style={{ marginTop: '0' }}>
          <Container>
            <Item.Group>{recommendedComponents}</Item.Group>

            <Grid stackable style={{ marginBottom: '2rem' }}>
              <Grid.Row>
                <Responsive as={Grid.Column} width={6} minWidth={768} />
                <Grid.Column width={4}>
                  <LoaderButton
                    primary
                    fluid
                    size="large"
                    text="See All Shows"
                    loadingText="Loading…"
                    onClick={this.handleAllShows}
                    isLoading={isLoading}
                    disabled={isLoading}
                  />
                </Grid.Column>
                <Responsive as={Grid.Column} width={6} minWidth={768} />
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </div>
    );
  }
}
