import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import LoadingSpinner from '../components/LoadingSpinner';

export default class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ContentComponent: null
    };
  }

  async componentDidMount() {
    const component = await import('../components/PrivacyDetail');

    if (component) {
      this.setState({ ContentComponent: component.default });
    }
  }

  render() {
    const { ContentComponent } = this.state;

    const { getCanonicalLink } = this.props;

    const helmet = (
      <Helmet>
        <title>Privacy Policy | Green Ocean</title>
        <link rel="canonical" href={getCanonicalLink('/privacypolicy')} />
        <meta
          name="description"
          content="This Privacy Statement explains our practices, including your choices, regarding the collection, use, and disclosure of certain information, including your personal information, by Green Ocean Team, LLC."
        />
      </Helmet>
    );

    if (!ContentComponent) {
      return (
        <div>
          {helmet}
          <LoadingSpinner text="Loading..." />
        </div>
      );
    }

    return (
      <div>
        {helmet}
        <ContentComponent />
      </div>
    );
  }
}
