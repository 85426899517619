import React, { Component } from "react";
import debounce from "lodash.debounce";
import ReactPixel from "react-facebook-pixel";
import LoaderButton from "./LoaderButton";
import SecondaryActionLink from "../components/SecondaryActionLink";
import * as TrackingEvents from "../libs/trackingEvents.js";
import { formatMoney } from "../libs/currencyLib.js";
import { getStates } from "../libs/states.js";
import {
  Segment,
  Header,
  Grid,
  Form,
  Message,
  Input,
  Dropdown,
  Transition,
  Dimmer,
  Loader,
  Responsive,
  Divider,
} from "semantic-ui-react";
import LoadingSpinner from "../components/LoadingSpinner";
import Text from "../components/Text";
import { getCurrentUser, publishSubscriptionAction } from "../libs/awsLib";
import {
  createSubscription,
  updateSubscription,
  getSubscriptionDetail,
  getSalesTaxRate,
} from "../libs/apiLib";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { parseQueryString } from "../libs/queryStringLib";
import "./CreditCardForm.css";

const DEBOUNCE_TIME = 750;
const NEW_SUBSCRIPTION_PRICE = 99.0;

export default class CreditCardForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      subscriptionSalesToCollect: null,
      subscriptionTotal: null,
      initialPaymentSalesToCollect: null,
      initialPaymentTotal: null,
      subscriptionPriceBeingCharged: NEW_SUBSCRIPTION_PRICE,
      subscriptionsTopicArn: null,
      addressIsValid: true,
      isReady: false,
      isLoading: false,
      isValid: true,
      subscriptionFailed: false,
      coupon: null,
      isTrial: false,
      trialEndUtc: null,
      errorMessage: "",
      cardNumber: false /* completed status */,
      cardExpiry: false /* completed status */,
      cardCvc: false /* completed status */,
      street: "",
      city: "",
      state: "",
      postalCode: "",
      fontSize: window.innerWidth < 450 ? "14px" : "20px"
    };

    var debounceOptions = {
      leading: false,
      trailing: true,
    };

    // https://css-tricks.com/debouncing-throttling-explained-examples/
    this.debouncedLookupSalesTax = debounce(this.lookupSalesTax, DEBOUNCE_TIME, debounceOptions);
    this.debouncedValidateStateAndPostalCode = debounce(
      this.validateStateAndPostalCode,
      DEBOUNCE_TIME,
      debounceOptions
    );
  }

  async componentDidMount() {
    const { location, coupon, isTrial, trialEndUtc } = this.props;

    const status = { coupon, isTrial, trialEndUtc };
    this.setState({ isReady: false, ...status });

    if (location && location.search) {
      var parsedQueryParams = parseQueryString(this.props.location.search);

      if (parsedQueryParams && parsedQueryParams.redirect) {
        var redirectLocation = decodeURIComponent(parsedQueryParams.redirect);
        this.setState({ redirect: redirectLocation });
      }
    }

    try {
      const cognitoUser = getCurrentUser();
      const subscriptionDetail = await getSubscriptionDetail(cognitoUser.username);

      if (!subscriptionDetail) {
        this.setState({ isReady: true });
        return;
      }

      const status = {
        isTrial: subscriptionDetail.isTrial,
        subscriptionPriceBeingCharged: subscriptionDetail.subscriptionPriceCharged,
      };

      if (subscriptionDetail.trialEndUtc) {
        status.trialEndUtc = subscriptionDetail.trialEndUtc;
      }

      if (subscriptionDetail.coupon) {
        status.coupon = subscriptionDetail.coupon;
      }

      if (!subscriptionDetail.address) {
        this.setState({
          isLoading: false,
          isReady: true,
          ...status,
        });
        return;
      }

      status.street = subscriptionDetail.address.street;
      status.city = subscriptionDetail.address.city;
      status.state = subscriptionDetail.address.state;
      status.postalCode = subscriptionDetail.address.postalCode;

      await this.setState(
        {
          ...status,
        },
        async () => {
          if (this.shouldCollectSalesTax()) {
            await this.lookupSalesTax();
          }
        }
      );

      this.setState({
        isLoading: false,
        isReady: true,
      });
    } catch (e) {
      console.log(e);
      this.setState({ isReady: true });
    }
  }

  createOptions = (fontSize) => {
    // The fonts referenced here are hosted
    // by stripe inside the iframe for each
    // element.
    return {
      style: {
        base: {
          fontSize: fontSize,
          color: "#495057",
          fontFamily: "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif",
          "::placeholder": {
            color: "#868e96",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    };
  };

  shouldCollectSalesTax = () => {
    const { state } = this.state;
    const result = state.toUpperCase() === "WA";
    return result;
  };

  validateStateAndPostalCode = async () => {
    const { street, city, state, postalCode } = this.state;

    if (!street || !city || !state || !postalCode || !/^\d{5}(-\d{4})?$/.test(postalCode)) {
      this.setState({
        addressIsValid: false,
      });
      return;
    }

    try {
      this.setState({ isLoading: true });

      await getSalesTaxRate(street, city, state, postalCode, NEW_SUBSCRIPTION_PRICE);

      this.setState({ isLoading: false, addressIsValid: true });
    } catch (e) {
      this.setState({
        isLoading: false,
        addressIsValid: false,
      });
      console.log("validateStateAndPostalCode failed.");
      console.log(e);
    }
  };

  lookupSalesTax = async () => {
    const { street, city, state, postalCode, coupon, subscriptionPriceBeingCharged } = this.state;

    if (!street || !city || !state || !postalCode || !/^\d{5}(-\d{4})?$/.test(postalCode)) {
      this.setState({
        subscriptionSalesTaxToCollect: null,
        subscriptionTotal: null,
        initialPaymentSalesTaxToCollect: null,
        initialPaymentTotal: null,
        addressIsValid: false,
      });
      return;
    }

    try {
      this.setState({ isLoading: true });

      if (!coupon) {
        const subscriptionSalesTaxResponse = await getSalesTaxRate(
          street,
          city,
          state,
          postalCode,
          subscriptionPriceBeingCharged
        );

        this.setState({
          subscriptionSalesTaxToCollect: subscriptionSalesTaxResponse.salesTaxToCollect,
          subscriptionTotal: subscriptionSalesTaxResponse.totalAmount,
        });
      }

      if (coupon) {
        const salesTaxToCollect = {
          subscriptionSalesTaxToCollect: "$0.00",
          subscriptionTotal: "$0.00",
          initialPaymentSalesTaxToCollect: "$0.00",
          initialPaymentTotal: "$0.00",
        };

        if (coupon.subsequentPayment !== 0) {
          const subscriptionSalesTaxResponse = await getSalesTaxRate(
            street,
            city,
            state,
            postalCode,
            coupon.subsequentPayment
          );

          salesTaxToCollect.subscriptionSalesTaxToCollect =
            subscriptionSalesTaxResponse.salesTaxToCollect;
          salesTaxToCollect.subscriptionTotal = subscriptionSalesTaxResponse.totalAmount;
        }

        if (coupon.initialPayment !== 0) {
          const discountSalesTaxResponse = await getSalesTaxRate(
            street,
            city,
            state,
            postalCode,
            coupon.initialPayment
          );

          salesTaxToCollect.initialPaymentSalesTaxToCollect =
            discountSalesTaxResponse.salesTaxToCollect;
          salesTaxToCollect.initialPaymentTotal = discountSalesTaxResponse.totalAmount;
        }

        this.setState(salesTaxToCollect);
      }

      this.setState({ isLoading: false, addressIsValid: true });
    } catch (e) {
      this.setState({
        subscriptionSalesTaxToCollect: null,
        subscriptionTotal: null,
        initialPaymentSalesTaxToCollect: null,
        initialPaymentTotal: null,
        addressIsValid: false,
        isLoading: false,
      });
      console.log("lookupSalesTax failed.");
      console.log(e);
    }
  };

  handleAddressInputChange = (e, data) => {
    // we set state to valid to make any invalid
    // messages go away and to enable the submit button
    // even if the value is not actually valid. We
    // do the actual validation test on submit.
    this.setState(
      {
        [e.target.id]: data.value,
        isLoading: false,
        isValid: true,
      },
      this.debounceAddressAndTaxValidation
    );
  };

  handleStateDropdownChange = (e, data) => {
    this.setState(
      {
        state: data.value,
        isLoading: false,
        isValid: true,
      },
      this.debounceAddressAndTaxValidation
    );
  };

  debounceAddressAndTaxValidation = () => {
    if (this.shouldCollectSalesTax()) {
      this.debouncedLookupSalesTax();
    } else {
      this.debouncedValidateStateAndPostalCode();
    }
  };

  handleChange = (e) => {
    // we set state to valid to make any invalid
    // messages go away and to enable the submit button
    // even if the value is not actually valid. We
    // do the actual validation test on submit.
    this.setState({
      [e.elementType]: e.complete,
      isValid: true,
      isLoading: false,
    });
  };

  updateSubscriptionDetails = async (token) => {
    const { email, history, setSubscribedStatus } = this.props;
    const { street, city, state, postalCode } = this.state;

    const cognitoUser = getCurrentUser();

    const payload = {
      token: token.id,
      email: email,
      userId: cognitoUser.username,
      address: {
        street,
        city,
        state,
        postalCode,
      },
    };

    try {
      await updateSubscription(cognitoUser.username, payload);
      await publishSubscriptionAction(cognitoUser.username, email, "UpdateAcknowleged");

      /* active */
      setSubscribedStatus({ statusCode: 1 }, () => {
        history.push("/account");
      });
    } catch (e) {
      console.log("update subscription failed.");
      console.log(e);

      this.setState({
        isLoading: false,
        isValid: true,
        errorMessage: "",
        subscriptionFailed: true,
      });

      window.heap &&
        window.heap.track(TrackingEvents.EXCEPTION_UPDATE_SUBSCRIPTION, {
          email,
        });
    }
  };

  addSubscription = async (token) => {
    const { email, history, setSubscribedStatus } = this.props;
    const { redirect, street, city, state, postalCode, coupon, isTrial } = this.state;

    const cognitoUser = getCurrentUser();

    const payload = {
      token: token.id,
      email: email,
      userId: cognitoUser.username,
      address: {
        street,
        city,
        state,
        postalCode,
      },
    };

    if (coupon && coupon.code) {
      payload.couponCode = coupon.code;
    }

    if (isTrial) {
      payload.isTrial = isTrial;
    }

    try {
      await createSubscription(payload);
      await publishSubscriptionAction(cognitoUser.username, email, "CreateAcknowleged");

      ReactPixel.track("CompleteRegistration");

      /* active */
      setSubscribedStatus({ statusCode: 1 }, () => {
        if (redirect) {
          history.push(redirect);
        } else {
          history.push("/");
        }
      });
    } catch (e) {
      console.log("create subscription failed.");
      console.log(e);

      this.setState({
        isLoading: false,
        isValid: true,
        errorMessage: "",
        subscriptionFailed: true,
      });

      window.heap &&
        window.heap.track(TrackingEvents.EXCEPTION_ADD_SUBSCRIPTION, {
          email,
        });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { update, stripe, elements } = this.props;
    const {
      cardNumber /* completed status */,
      cardExpiry /* completed status */,
      cardCvc /* completed status */,
      subscriptionSalesTaxToCollect,
      addressIsValid,
      street,
      city,
      state,
      postalCode
    } = this.state;

    this.setState({ isLoading: true });

    if (!(cardNumber && cardExpiry && cardCvc)) {
      this.setState({
        isLoading: false,
        isValid: false,
        errorMessage: "Please complete the credit card form fields",
      });
      return;
    }

    if (!street || !city || !state || !postalCode) {
      this.setState({
        isLoading: false,
        isValid: false,
        errorMessage: "Please complete the address form fields",
      });
      return;
    }

    if (!/^\d{5}(-\d{4})?$/.test(postalCode)) {
      this.setState({
        isValid: false,
        isLoading: false,
        errorMessage: "Zip needs to be 12345 or 12345-6789 format",
      });
      return;
    }

    if (this.shouldCollectSalesTax() && !subscriptionSalesTaxToCollect) {
      this.setState({
        isValid: false,
        isLoading: false,
        errorMessage:
          "We could not find a tax rate for that address. Please check your zip is correct.",
      });
      return;
    }

    if (!addressIsValid) {
      this.setState({
        isValid: false,
        isLoading: false,
        errorMessage:
          "We could not find that address. Please check your state and zip are correct.",
      });
      return;
    }

    // Use elements.getElement to get a reference to the mounted Element.
    const cardNumberElement = elements.getElement(CardNumberElement);

    // this call to createToken knows which Element(s) to tokenize for stripe
    stripe.createToken(cardNumberElement).then(async ({ error, token }) => {
      if (error) {
        this.setState({
          isLoading: false,
          isValid: false,
          errorMessage: error.message,
        });
        return;
      }

      if (update) {
        await this.updateSubscriptionDetails(token);
      } else {
        await this.addSubscription(token);
      }
    });
  };

  handleCancel = (e) => {
    e.preventDefault();

    const { history } = this.props;
    const { redirect } = this.state;

    this.setState({ isLoading: true });

    setTimeout(() => {
      if (redirect) {
        history.push(redirect);
      } else {
        history.push("/account");
      }
    }, 200);
  };

  render() {
    const { update, isSubscribed } = this.props;
    const {
      isLoading,
      isReady,
      isValid,
      errorMessage,
      subscriptionFailed,
      subscriptionSalesTaxToCollect,
      subscriptionTotal,
      initialPaymentTotal,
      subscriptionPriceBeingCharged,
      street,
      city,
      state,
      postalCode,
      coupon,
      isTrial,
      trialEndUtc,
    } = this.state;

    if (!isReady) {
      return (
        <div>
          <LoadingSpinner text="Loading ..." />
        </div>
      );
    }

    const validationFailedMessage = (
      <div style={{ marginTop: "1em" }}>
        <Message warning icon="warning sign" header="Invalid information" content={errorMessage} />
      </div>
    );

    const subscriptionFailedMessage = (
      <div style={{ marginTop: "1em" }}>
        <Message
          warning
          icon="warning sign"
          header="Subscription failed"
          content="Your payment failed and your subscription could not be processed. Please try again."
        />{" "}
      </div>
    );

    const chargedNextTimeMessage = (
      <div style={{ marginTop: "2em" }}>
        <Message info>
          Your new card details will be used the next time your subscription renews.
        </Message>
      </div>
    );

    const couponCodeApplied = coupon ? (
      <div style={{ marginTop: "2em" }}>
        <Message info>
          Your GO discount code has been applied. See Summary below for details.
        </Message>
      </div>
    ) : null;

    const trialEndMessage = trialEndUtc
      ? ` on ${new Date(trialEndUtc).toLocaleDateString()}`
      : null;
    const trialMessage = `You will not be charged during yor free trial period. Amounts shown here will apply when your free trial ends${trialEndMessage}.`;

    const freeTrialUnderway =
      isSubscribed && update && isTrial ? (
        <div style={{ marginTop: "2em" }}>
          <Message info icon="warning sign" header="Trial Period" content={trialMessage} />
        </div>
      ) : null;

    const freeTrialStarting =
      !isSubscribed && !update && isTrial ? (
        <div style={{ marginTop: "2em" }}>
          <Message info icon="warning sign" header="Trial Period" content={trialMessage} />
        </div>
      ) : null;

    const termsOfUse = (
      <div style={{ marginTop: "2em" }}>
        <Segment>
          <Text size="medium">
            By clicking 'Subscribe' you are agreeing to our{" "}
            <a href="/terms" target="_blank">
              Terms of Use
            </a>{" "}
            and{" "}
            <a href="/privacypolicy" target="_blank">
              Privacy Policy
            </a>
            .
          </Text>
        </Segment>
      </div>
    );

    const cancelButton = (
      <SecondaryActionLink>
        <a href="" onClick={this.handleCancel}>
          Cancel
        </a>
      </SecondaryActionLink>
    );

    const stateOptions = getStates().map((state) => ({
      key: state.abbreviation,
      text: state.name,
      value: state.abbreviation,
    }));

    const couponFirstMonthDiscount = coupon && coupon.initialPayment !== coupon.subsequentPayment;

    const isCollectingSalesTax = this.shouldCollectSalesTax();
    const collectingSalesTaxFirstMonthDiscount =
      isCollectingSalesTax && subscriptionTotal !== initialPaymentTotal;

    const showSubsequentMessaging =
      couponFirstMonthDiscount || collectingSalesTaxFirstMonthDiscount;

    return (
      <div>
        {!isValid && validationFailedMessage}
        {subscriptionFailed && subscriptionFailedMessage}
        {freeTrialStarting}
        {freeTrialUnderway}
        {couponCodeApplied}

        <Form onSubmit={this.handleSubmit}>
          <Grid stackable style={{ marginTop: "2em" }}>
            <Grid.Row stretched>
              <Grid.Column width={16}>
                <label>
                  Card Number
                  <CardNumberElement
                    onChange={this.handleChange}
                    onReady={(el) => el.focus()}
                    options={this.createOptions(this.state.fontSize)}
                  />
                </label>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Grid stackable>
            <Grid.Row stretched>
              <Grid.Column width={8}>
                <label>
                  Expires
                  <CardExpiryElement
                    onChange={this.handleChange}
                    options={this.createOptions(this.state.fontSize)}
                  />
                </label>
              </Grid.Column>
              <Grid.Column width={8}>
                <label>
                  CVC
                  <CardCvcElement
                    onChange={this.handleChange}
                    options={this.createOptions(this.state.fontSize)}
                  />
                </label>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Grid stackable style={{ marginTop: "2em" }}>
            <Grid.Row stretched>
              <Grid.Column width={16}>
                <Input
                  placeholder="Street"
                  maxLength="128"
                  fluid
                  focus
                  type="text"
                  id="street"
                  size="huge"
                  value={street}
                  onChange={this.handleAddressInputChange}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Grid stackable style={{ marginTop: "2em" }}>
            <Grid.Row stretched>
              <Grid.Column width={16}>
                <Input
                  placeholder="City"
                  maxLength="128"
                  fluid
                  focus
                  type="text"
                  id="city"
                  size="huge"
                  value={city}
                  onChange={this.handleAddressInputChange}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Grid stackable style={{ marginTop: "2em" }}>
            <Grid.Row stretched>
              <Grid.Column width={16}>
                <Dropdown
                  className={"huge CreditCardForm-dropdown"}
                  size="huge"
                  id="state"
                  placeholder="State"
                  search
                  selection
                  fluid
                  options={stateOptions}
                  value={state}
                  onChange={this.handleStateDropdownChange}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Grid stackable style={{ marginTop: "2em" }}>
            <Grid.Row stretched>
              <Grid.Column width={16}>
                <Input
                  placeholder="Zip"
                  maxLength="32"
                  fluid
                  focus
                  type="text"
                  id="postalCode"
                  size="huge"
                  value={postalCode}
                  onChange={this.handleAddressInputChange}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Grid style={{ marginTop: "2em" }}>
            <Grid.Row>
              <Grid.Column width={16} verticalAlign="middle">
                <Header as="h3">Summary</Header>
              </Grid.Column>
            </Grid.Row>

            {showSubsequentMessaging && coupon && !isCollectingSalesTax && (
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <p>1st Month Payment</p>
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle" textAlign="right">
                  <p>{formatMoney(coupon.initialPayment)}</p>
                </Grid.Column>
              </Grid.Row>
            )}
            {showSubsequentMessaging && coupon && isCollectingSalesTax && (
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <p>1st Month Payment (inc. sales tax)</p>
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle" textAlign="right">
                  <Dimmer active={isLoading} inverted />
                  <p>{initialPaymentTotal}</p>
                </Grid.Column>
              </Grid.Row>
            )}

            {showSubsequentMessaging && coupon && isCollectingSalesTax && (
              <Grid.Row>
                <Grid.Column width={16}>
                  <Divider horizontal>Subsequent Months</Divider>
                </Grid.Column>
              </Grid.Row>
            )}

            {coupon && !isCollectingSalesTax && (
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <p>
                    {showSubsequentMessaging ? "Subsequent " : ""}
                    Monthly Payment
                  </p>
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle" textAlign="right">
                  <p>{formatMoney(coupon.subsequentPayment)}</p>
                </Grid.Column>
              </Grid.Row>
            )}
            {!coupon && (
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <p>Subscription - Monthly</p>
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle" textAlign="right">
                  <p>{formatMoney(subscriptionPriceBeingCharged)}</p>
                </Grid.Column>
              </Grid.Row>
            )}
            {coupon && isCollectingSalesTax && (
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <p>Subscription - Monthly</p>
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle" textAlign="right">
                  <p>{formatMoney(coupon.subsequentPayment)}</p>
                </Grid.Column>
              </Grid.Row>
            )}

            <Transition visible={isCollectingSalesTax} duration={300} unmountOnHide>
              <Grid.Row>
                <Grid.Column width={6} verticalAlign="middle">
                  <p>Sales Tax</p>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Dimmer active={isLoading} inverted>
                    <Loader inverted />
                  </Dimmer>
                </Grid.Column>
                <Grid.Column width={6} verticalAlign="middle" textAlign="right">
                  <Dimmer active={isLoading} inverted />
                  <p>{subscriptionSalesTaxToCollect}</p>
                </Grid.Column>
              </Grid.Row>
            </Transition>

            <Transition visible={isCollectingSalesTax} duration={300} unmountOnHide>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <p>
                    <strong>Total Monthly Payment</strong>
                  </p>
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle" textAlign="right">
                  <Dimmer active={isLoading} inverted />
                  <p>
                    <strong>{subscriptionTotal}</strong>
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Transition>
          </Grid>

          {update && isSubscribed && chargedNextTimeMessage}
          {!update && !isSubscribed && termsOfUse}

          <Grid stackable style={{ marginTop: "2em" }}>
            <Grid.Row>
              <Responsive
                as={Grid.Column}
                width={12}
                align="right"
                verticalAlign="middle"
                minWidth={768}
              >
                {update && cancelButton}
              </Responsive>

              <Grid.Column width={4} align="right" verticalAlign="middle">
                <LoaderButton
                  fluid
                  primary
                  size="big"
                  isLoading={isLoading}
                  text={update ? "Update" : "Subscribe"}
                  type="submit"
                  disabled={isLoading || !isValid}
                />
              </Grid.Column>

              <Responsive as={Grid.Column} align="right" verticalAlign="middle" maxWidth={767}>
                {update && cancelButton}
              </Responsive>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    );
  }
}
