import React from 'react';

import { Segment, Container, Header } from 'semantic-ui-react';

import './MiniMasthead.css';

export default ({ text }) => (
  <Segment inverted vertical className="MiniMasthead-background">
    <Container text>
      <Header inverted as="h1" textAlign="center" content={text} />
    </Container>
  </Segment>
);
