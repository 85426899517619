import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import LoadingSpinner from '../components/LoadingSpinner';

export default class Terms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ContentComponent: null
    };
  }

  async componentDidMount() {
    const component = await import('../components/TermsDetail');

    if (component) {
      this.setState({ ContentComponent: component.default });
    }
  }

  render() {
    const { ContentComponent } = this.state;

    const { getCanonicalLink } = this.props;

    const helmet = (
      <Helmet>
        <title>Terms of Use | Green Ocean</title>
        <link rel="canonical" href={getCanonicalLink('/terms')} />
        <meta
          name="description"
          content="These Terms of Use govern your use of our service."
        />
      </Helmet>
    );

    if (!ContentComponent) {
      return (
        <div>
          {helmet}
          <LoadingSpinner text="Loading..." />
        </div>
      );
    }

    return (
      <div>
        {helmet}
        <ContentComponent />
      </div>
    );
  }
}
