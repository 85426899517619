import React, { Component } from "react";

import { Helmet } from "react-helmet";

import PricingDetail from "../components/PricingDetail";

import MiniMasthead from "../components/MiniMasthead";

export default class Pricing extends Component {
  handleSubscribeClicked = (e) => {
    e.preventDefault();

    setTimeout(() => {
      this.props.history.push("/signup");
    }, 300);
  };

  handleContactUsClicked = (e) => {
    e.preventDefault();

    setTimeout(() => {
      this.props.history.push("/contactus");
    }, 300);
  };

  render() {
    const { isSubscribed, isCancelled, getCanonicalLink } = this.props;

    const pricingProps = {
      handleSubscribeClicked: this.handleSubscribeClicked,
      handleContactUsClicked: this.handleContactUsClicked,
    };

    if (isSubscribed || isCancelled) pricingProps.buttonText = "Subscribe";

    const canonical = getCanonicalLink("/pricing");

    return (
      <div>
        <Helmet>
          <title>Pricing Plans | Green Ocean</title>
          <link rel="canonical" href={canonical} />
          <meta
            name="description"
            content="Start your 7-day free trial now, with Agent, Team and Office pricing plans available."
          />
        </Helmet>
        <MiniMasthead text="Pricing" />
        <PricingDetail {...pricingProps} />
      </div>
    );
  }
}
