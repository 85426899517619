import React, { Component } from 'react';

import { Grid, Image } from 'semantic-ui-react';

import './ChapterItem.css';

export default class ChapterItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };

    this.handleChapterClick = this.handleChapterClick.bind(this);
  }

  handleChapterClick(e) {
    e && e.preventDefault();

    const { history, showId, episodeId, chapterId } = this.props;

    this.setState({ isLoading: true });

    const route = `/watch/show/${showId}/episode/${episodeId}/chapter/${chapterId}`;

    setTimeout(() => {
      history.push(route);
    }, 200);
  }

  render() {
    const { index, thumbnails, chapterIndex, thumbnailSize } = this.props;

    let imgSrc;

    try {
      imgSrc = thumbnails[thumbnailSize];
    } catch (e) {
      console.log(`no ${thumbnailSize} thumb`);
    }

    const className =
      index === chapterIndex
        ? 'ChapterItem-NumberCurrent'
        : 'ChapterItem-NumberNormal';

    return (
      <Grid.Column>
        <div onClick={this.handleChapterClick} className="ChapterItem">
          <div className={className}>{index}</div>
          <Image src={imgSrc} fluid />
        </div>
      </Grid.Column>
    );
  }
}
