import React, { Component } from "react";
import Player from "./Player";
import ChapterPartDetail from "./ChapterPartDetail";
import ChapterTasks from "./ChapterTasks";

export default class ChapterDetail extends Component {
  render() {
    const {
      showId,
      showName,
      episodeId,
      chapterId,
      episodeIndex,
      episodeName,
      chapterIndex,
      episodeDurationSeconds,
      chapters,
      handlePartClicked,
      getNextVideo,
      playerEventsBound,
      setPlayerEventsBound,
      getPlayerEventsBound,
      getCurrentLocation,
      autoplay,
      chapterActions,
      handleActionsClicked,
      username,
    } = this.props;

    // find which chapter part we are on

    let i = 0;
    let j = chapters ? chapters.length : 0;
    let currentChapter = null;

    while (!currentChapter && i < j) {
      if (chapters[i].id === chapterId) {
        currentChapter = chapters[i];
      }
      i++;
    }

    const childProps = {
      showId,
      showName,
      episodeId,
      chapterId,
      episodeIndex,
      episodeName,
      chapterIndex,
      episodeDurationSeconds,
      handlePartClicked,
      getNextVideo,
      playerEventsBound,
      setPlayerEventsBound,
      getPlayerEventsBound,
      getCurrentLocation,
      chapters,
      partCount: chapters.length,
      autoplay,
      chapterActions,
      username,
      handleActionsClicked,
      ...currentChapter,
    };

    return (
      <div>
        <Player {...childProps} />
        <ChapterPartDetail {...childProps} />
        <ChapterTasks {...childProps} />
      </div>
    );
  }
}
