import React, { Component } from 'react';

import { Checkbox } from 'semantic-ui-react';

import { postChapterActionTask } from '../libs/apiLib';

import * as TrackingEvents from '../libs/trackingEvents.js';

import './ChapterTaskItem.css';

export default class ChapterTaskItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: props.status
    };
  }

  handleTaskItemClicked = async e => {
    const {
      itemIndex,
      username,
      showId,
      episodeId,
      chapterId,
      handleActionsClicked
    } = this.props;

    const nextChecked = !this.state.checked;

    this.setState({ checked: nextChecked });

    const postBody = {
      accountId: username,
      showId,
      episodeId,
      chapterId,
      taskIndex: itemIndex,
      taskValue: nextChecked
    };

    window.heap &&
      window.heap.track(
        nextChecked
          ? TrackingEvents.CHECK_CHAPTER_TASK
          : TrackingEvents.UNCHECK_CHAPTER_TASK,
        postBody
      );

    await postChapterActionTask(postBody);

    await handleActionsClicked();
  };

  render() {
    const { description } = this.props;

    return (
      <div className="ChapterTaskItem-wrapper">
        <Checkbox
          label={{ children: description }}
          checked={this.state.checked}
          onClick={this.handleTaskItemClicked}
        />
      </div>
    );
  }
}
