import React, { Component } from 'react';
import {
  Segment,
  Container,
  Header,
  Message,
  Grid,
  Form,
  Responsive,
  Input,
  TextArea
} from 'semantic-ui-react';

import { Helmet } from 'react-helmet';
import MiniMasthead from '../components/MiniMasthead';
import LoaderButton from '../components/LoaderButton';
import SecondaryActionLink from '../components/SecondaryActionLink';
import * as TrackingEvents from '../libs/trackingEvents.js';
import { validateEmail } from '../libs/validateEmail';
import { postContactForm } from '../libs/apiLib';

import './ContactUs.css';

export default class ContactUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: this.props.email ? this.props.email : '',
      subject: '',
      message: '',
      isLoading: false,
      isValid: true,
      submitted: false
    };

    this.focusEmailInput = this.focusEmailInput.bind(this);

    this.masthead = <MiniMasthead text="Contact Us" />;
  }

  focusEmailInput() {
    // Explicitly focus the email text input using the raw DOM API
    this.emailInput && this.emailInput.focus();
  }

  handleChange = (e, data) => {
    // we set state to valid to make any invalid
    // messages go away and to enable the submit button
    // even if the value is not actually valid. We
    // do the actual validation test on submit.
    this.setState({
      [e.target.id]: data.value,
      isValid: true,
      isLoading: false
    });
  };

  handleCancel = e => {
    e.preventDefault();
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.props.history.push('/');
    }, 200);
  };

  handleSubmit = async e => {
    e.preventDefault();

    var { email, subject, message } = this.state;

    this.setState({ isLoading: true });

    const validData = validateEmail(email);

    if (!validData) {
      this.setState({ isValid: false, isLoading: false });
      return;
    }

    const payload = {
      email,
      subject,
      message
    };

    try {
      await postContactForm(payload);
      this.setState({ isLoading: false, submitted: true });
    } catch (e) {
      this.setState({ isLoading: false, submitted: true });

      console.log(e);

      window.heap &&
        window.heap.track(TrackingEvents.EXCEPTION_CONTACT, {
          email
        });
    }
  };

  render() {
    const { isLoading, submitted, isValid, email } = this.state;

    const { getCanonicalLink } = this.props;

    const validationMessage = isValid ? null : (
      <Message
        style={{ marginTop: '2em' }}
        warning
        icon="warning sign"
        content="Please enter a valid email address."
      />
    );

    const helmet = (
      <Helmet>
        <title>Contact Us | Green Ocean</title>
        <link rel="canonical" href={getCanonicalLink('/contactus')} />
        <meta
          name="description"
          content="Email us directly using the provided Contact Us form."
        />
      </Helmet>
    );

    if (submitted) {
      return (
        <div>
          {helmet}
          {this.masthead}

          <Container text textAlign="left" style={{ marginTop: '2em' }}>
            <Segment basic size="huge">
              <Header as="h3">Thank you. Your message has been sent.</Header>
            </Segment>
          </Container>
        </div>
      );
    }

    return (
      <div>
        {helmet}
        {this.masthead}

        <Container text textAlign="left" style={{ marginTop: '2em' }}>
          <Segment basic size="huge">
            <Header as="h3">Use the form below to drop us an email</Header>

            {validationMessage}

            <Form onSubmit={this.handleSubmit} className="ContactUs">
              <Input
                label="From"
                placeholder="Your email address"
                fluid
                focus
                type="text"
                id="email"
                name="email"
                size="huge"
                defaultValue={email ? email : ''}
                onChange={this.handleChange}
                ref={input => {
                  this.emailInput = input;
                }}
                style={{ marginTop: '2em' }}
              />

              <Input
                list="subjects"
                label="Subject"
                id="subject"
                name="subject"
                fluid
                size="huge"
                onChange={this.handleChange}
                placeholder="Choose subject..."
                style={{ marginTop: '2em' }}
              />
              <datalist id="subjects">
                <option value="Pricing Plans" />
                <option value="Technical Support" />
                <option value="Billing" />
                <option value="Other" />
              </datalist>

              <TextArea
                id="message"
                name="message"
                size="huge"
                onChange={this.handleChange}
                label="Tell us more"
                placeholder="Tell us more"
                style={{ marginTop: '2em', minHeight: 200 }}
              />

              <Responsive minWidth={768}>
                <Grid style={{ marginTop: '2em' }}>
                  <Grid.Row stretched>
                    <Grid.Column
                      verticalAlign="middle"
                      align="right"
                      width={12}
                    >
                      <SecondaryActionLink>
                        <a href="" onClick={this.handleCancel}>
                          Cancel
                        </a>
                      </SecondaryActionLink>
                    </Grid.Column>

                    <Grid.Column align="right" verticalAlign="middle" width={4}>
                      <LoaderButton
                        primary
                        fluid
                        size="big"
                        isLoading={isLoading}
                        text="Send"
                        type="submit"
                        disabled={isLoading || !isValid}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Responsive>

              <Responsive maxWidth={767}>
                <Grid stackable style={{ marginTop: '2em' }}>
                  <Grid.Row>
                    <Grid.Column align="right" verticalAlign="middle">
                      <LoaderButton
                        primary
                        fluid
                        size="big"
                        isLoading={isLoading}
                        text="Send"
                        type="submit"
                        disabled={isLoading || !isValid}
                      />
                    </Grid.Column>
                    <Grid.Column verticalAlign="middle" align="right">
                      <SecondaryActionLink>
                        <a href="" onClick={this.handleCancel}>
                          Cancel
                        </a>
                      </SecondaryActionLink>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Responsive>
            </Form>
          </Segment>
        </Container>
      </div>
    );
  }
}
