import React from 'react';
import { Route } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

export default props => (
  <Route
    path={props.href}
    exact
    children={({ match, history }) => (
      <Button
        onClick={e => {
          e.preventDefault();
          history.push(e.currentTarget.getAttribute('href'));
        }}
        {...props}
      >
        {props.children}
      </Button>
    )}
  />
);
