import React, { Component } from 'react';

import { Container, Segment, Grid, Header } from 'semantic-ui-react';

import ChapterItem from './ChapterItem';

export default class Chapters extends Component {
  render() {
    const {
      chapters,
      showId,
      episodeId,
      chapterIndex,
      history,
      thumbnailSize
    } = this.props;

    const chapterComponents =
      chapters &&
      chapters.map((item, i) => {
        const itemProps = Object.assign(
          {},
          { ...item },
          { showId, episodeId, chapterIndex, history, thumbnailSize },
          { chapterId: item.id }
        );
        return <ChapterItem index={i + 1} key={item.id} {...itemProps} />;
      }, this);

    return (
      <div>
        <Segment basic style={{ marginTop: '2em', marginBottom: '0' }}>
          <Container>
            <Header as="h3">Scenes In This Episode</Header>
          </Container>
        </Segment>

        <Segment basic>
          <Container>
            <Grid verticalAlign="middle" stackable columns="5">
              {chapterComponents}
            </Grid>
          </Container>
        </Segment>
      </div>
    );
  }
}
