import React from "react";
import { Switch } from "react-router-dom";
import Homepage from "./containers/Homepage";
import SignUp from "./containers/SignUp";
import Login from "./containers/Login";
import Account from "./containers/Account";
import Subscribe from "./containers/Subscribe";
import UpdateSubscription from "./containers/UpdateSubscription";
import CancelSubscription from "./containers/CancelSubscription";
import SetPassword from "./containers/SetPassword";
import SetEmail from "./containers/SetEmail";
import Watch from "./containers/Watch";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import Terms from "./containers/Terms";
import Pricing from "./containers/Pricing";
import Shows from "./containers/Shows";
import Show from "./containers/Show";
import NotFound from "./containers/NotFound";
import ContactUs from "./containers/ContactUs";
import AdOnboarding from "./containers/AdOnboarding";
import Coupon from "./containers/Coupon";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";

export default ({ childProps }) => (
  <Switch>
    <AppliedRoute path="/signup" exact component={SignUp} props={childProps} />

    <AppliedRoute path="/login" exact component={Login} props={childProps} />

    <AppliedRoute path="/shows" exact component={Shows} props={childProps} />

    <AppliedRoute path="/contactus" exact component={ContactUs} props={childProps} />

    <AppliedRoute path="/show/:showId" exact component={Show} props={childProps} />

    <AppliedRoute path="/setpassword/:email" exact component={SetPassword} props={childProps} />

    <AppliedRoute
      path="/setpassword/:token/:email"
      exact
      component={SetPassword}
      props={childProps}
    />

    <AppliedRoute path="/setpassword" exact component={SetPassword} props={childProps} />

    <AuthenticatedRoute path="/setemail/:token" component={SetEmail} props={childProps} />

    <AuthenticatedRoute path="/setemail" exact component={SetEmail} props={childProps} />

    <AuthenticatedRoute path="/subscribe" exact component={Subscribe} props={childProps} />

    <AuthenticatedRoute
      path="/updatesubscription"
      exact
      component={UpdateSubscription}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/cancelsubscription"
      exact
      component={CancelSubscription}
      props={childProps}
    />

    <AuthenticatedRoute path="/account" exact component={Account} props={childProps} />

    <AuthenticatedRoute
      path="/account/apply-coupon-code"
      exact
      component={Coupon}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/watch/show/:showId/episode/:episodeId/chapter/:chapterId/:autoplay?"
      exact={false}
      component={Watch}
      props={childProps}
    />

    <AppliedRoute path="/terms" exact component={Terms} props={childProps} />

    <AppliedRoute path="/privacypolicy" exact component={PrivacyPolicy} props={childProps} />

    <AppliedRoute path="/terms" exact component={Terms} props={childProps} />

    <AppliedRoute path="/pricing" exact component={Pricing} props={childProps} />

    <AppliedRoute path="/learn/:videoId" exact component={AdOnboarding} props={childProps} />

    <AppliedRoute path="/watch/:videoId" exact component={AdOnboarding} props={childProps} />

    <AppliedRoute
      path="/promo/:videoId/:action(offer|signup)"
      exact
      component={AdOnboarding}
      props={childProps}
    />

    <AppliedRoute
      path="/promo/:videoId"
      exact={false}
      component={AdOnboarding}
      props={childProps}
    />

    <AuthenticatedRoute path="/" exact component={Homepage} props={childProps} />

    {/* Finally, catch all unmatched routes */}
    <AppliedRoute component={NotFound} props={childProps} />
  </Switch>
);
